import React from 'react'
import { Grid } from "@material-ui/core";
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

import { makeStyles } from '@material-ui/core'
import im01 from '../../../src/Home.png'


const useStyles = makeStyles((theme) => ({

  parentGrid:{
  
    [theme.breakpoints.up('xs')]: {
      // height:"350px",
     
    },

    [theme.breakpoints.up('sm')]: {
      // height:"640px",
     
    },
    
    [theme.breakpoints.up('md')]: {
      // height:"680px",
    
    },

  },


    FisrtText: {

      [theme.breakpoints.up('xs')]: {
        opacity:"50%",
        fontSize:"13px",
        color:"white",
        fontWeight:300
      },
  
      [theme.breakpoints.up('sm')]: {
        opacity:"50%",
        fontSize:"16px",
        color:"white",
        fontWeight:300
      },
      
      [theme.breakpoints.up('md')]: {
        opacity:"50%",
        fontSize:"18px",
        color:"white",
        fontWeight:300
      },

      [theme.breakpoints.up('lg')]: {
        opacity:"50%",
        fontSize:"20px",
        color:"white",
        fontWeight:300
      },
      
  
    },
  
    parentText: {

      [theme.breakpoints.up('xs')]: {
        position:"absolute",
        top: "65px",
        left: "5%",
        // width:"170vh"
  
      },

      [theme.breakpoints.up('sm')]: {
        position:"absolute",
        top: "90px",
        left: "6%",
        // width:"170vh"
  
      },

      [theme.breakpoints.up('md')]: {
        position:"absolute",
        top: "150px",
        left: "6%",
        // width:"170vh"
  
      },

      [theme.breakpoints.up('lg')]: {
        position:"absolute",
        top: "250px",
        left: "6%",
        // width:"170vh"
  
      },
    },
  
    FirstTitle: {


      [theme.breakpoints.up('xs')]: {
        fontSize:"23px",
        color:"white",
        // lineHeight:"66px",
        marginTop:"12px"

      },

      [theme.breakpoints.up('sm')]: {
        fontSize:"40px",
        color:"white",
        lineHeight:"42px",
        marginTop:"23px"

      },

      [theme.breakpoints.up('md')]: {
        fontSize:"53px",
        color:"white",
        lineHeight:"66px",
        marginTop:"25px"

      },

      [theme.breakpoints.up('lg')]: {
        fontSize:"60px",
        color:"white",
        lineHeight:"66px",
        marginTop:"25px"

      },
    },
       
    SecondeTitle: {


      [theme.breakpoints.up('xs')]: {
        fontSize:"32px",
        color:"#85ffbd",
        lineHeight:"30px",
      },

      [theme.breakpoints.up('sm')]: {
        fontSize:"45px",
        color:"#85ffbd",
        lineHeight:"60px",
      },

      [theme.breakpoints.up('md')]: {
        fontSize:"60px",
        color:"#85ffbd",
        lineHeight:"66px",
      },
    },
       
  
    Description:{

      [theme.breakpoints.up('xs')]: {
        fontSize:"10px",
        color:"white",
        opacity:"70%",
        marginTop:"17px",
        fontWeight:400

      },

      [theme.breakpoints.up('sm')]: {
        fontSize:"15px",
        color:"white",
        opacity:"70%",
        marginTop:"17px",
        fontWeight:400
      },

      [theme.breakpoints.up('md')]: {
        fontSize:"16px",
        color:"white",
        opacity:"70%",
        marginTop:"17px",
        fontWeight:400
      },
    },


    // parentChildren: {

    //   [theme.breakpoints.up('xs')]: {
    //     height:"100%",
    //     marginTop:"-50px"

    //   },

    //   // [theme.breakpoints.up('sm')]: {
    //   //   height:"100%",
    //   //   marginTop:"-90px"

    //   // },

      
    // },

    // image: {
    //   [theme.breakpoints.up('xs')]: {
    //     marginBottom:"150px"
       

    //   },

    //   [theme.breakpoints.up('sm')]: {
    //     marginBottom:"350px"
       

    //   },

    //   [theme.breakpoints.up('md')]: {
    //     marginBottom:"200px"
    //   },

    // }
  }));




function NewsCenterPage({children,whatwedo,WhiteTitle,AquaTitle,description,BusinessImage}) {
    const classes = useStyles();

    return (
      <Grid container item xs={12}>
        <Grid item xs={12}> <Navbar  colorNewsCenter="#85ffbd" /> </Grid>
        
        <Grid container item xs={12}>
              <Grid container item xs={12}  className={classes.parentGrid}>
                
                  <img
                          // className={classes.image}
                          src={BusinessImage}
                          alt="AVH Holding"
                          style={{width:"100%",height:"100%"}}
                  />
                  <Grid container item xs={11} className={classes.parentText}>

                      {whatwedo !="" ?
                      <Grid item xs={12} className={classes.FisrtText}>
                        {whatwedo} 
                      </Grid>
                      :<></>}
                      
                      <Grid item xs={12} style={{height:"180px"}}>

                        {WhiteTitle !="" ?
                        <Grid item xs={12} className={classes.FirstTitle}>
                          {WhiteTitle}
                        </Grid>
                        :<></>}


                        {AquaTitle !="" ?
                        <Grid item xs={12} className={classes.SecondeTitle}>
                          {AquaTitle}
                        </Grid>
                        :<></> }

                        {description !="" ?
                        <Grid item xs={11} className={classes.Description}>
                            {description}
                        </Grid>
                        :<></> }
                    </Grid>
                  </Grid>
              </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.parentChildren} >
          {children}
        </Grid>
        <Grid item xs={12}> <Footer /> </Grid>
      </Grid>
    )
}

export default NewsCenterPage
