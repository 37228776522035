export const News = {
    GET_NEWS: 'GET_NEWS',
    GET_AVH_NEWS: 'GET_AVH_NEWS'
}

export const AvhNews = {
    GET_AVH_NEWS_DETAILS: 'GET_AVH_NEWS_DETAILS',
    GET_MORE_NEWS: 'GET_MORE_NEWS'
}

export const Contact = {
    CONTACT_START: 'CONTACT_START',
    CONTACT_END: 'CONTACT_END',
    CONTACT_FAIL: 'CONTACT_FAIL',
    GET_CONTACT_INFO: 'GET_CONTACT_INFO'
}

export const SendCv = {
    SEND_START: 'SEND_START',
    SEND_END: 'SEND_END',
    SEND_FAIL: 'SEND_FAIL',
    GET_SEND_INFO: 'GET_SEND_INFO'
}

export const StartUp = {
    StartUp_START: 'StartUp_START',
    StartUp_END: 'StartUp_END',
    StartUp_FAIL: 'StartUp_FAIL',
    GET_StartUp_INFO: 'GET_StartUp_INFO'
}



export const TestAction = {
    GET_INFO_TEST: 'GET_INFO_TEST',
    SUBMIT_TEST: 'SUBMIT_TEST'
}