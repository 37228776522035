import AboutAVH from "../../components/AboutAVH/AboutAVH";
import SmallImgText from "../../components/SmallImText/SmallImgText";
import SmallTextImg from "../../components/SmallTextImg/SmallTextImg";
import { Grid } from "@material-ui/core";
import "./About.css";
import MessageCEO from "../../components/MessageCEO/MessageCEO";
import { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="About-page">
      <Navbar />
      <AboutAVH />
      <Grid container className="gr" style={{ marginTop: "100px" }}>
        <Grid item lg={6}>
          <SmallTextImg Animated />
        </Grid>
        <Grid item lg={6}>
          <SmallImgText Animated />
        </Grid>
      </Grid>
      <MessageCEO Animated />
      <Footer />
    </div>
  );
}

export default About;
