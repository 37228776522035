import AuthorInfo from "../../../components/AuthorInfo/AuthorInfo";
import ImageLargeTextt from "../../../components/ImageLargeText/ImageLargeText";
import ImgTextBelow from "../../../components/ImgTextBelow/ImgTextBelow";
import NewsCenterHome from "../../../components/NewsCenterHome/NewsCenterHome";
import { Grid } from "@material-ui/core";
import { imgtextbelowdata4, imgtextbelowdata5, links } from "../NewsData";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { connect } from 'react-redux';
import * as AvhNewsActionCreators from '../../../store/actionsCreators/AvhNewsActionCreator';
import { useEffect, useState } from "react";
import AvhLoader from "../Loader";

function SecondNews({news,getAvhNews,news1,news2,getNews}) {

  useEffect(()=>{
    getAvhNews();
    getNews();
  },[])

  const [isLoading,setIsLoading] = useState(true);

  useEffect(()=>{
    if(news!== '' &&news1!== '' &&news2!== ''){
      setIsLoading(false);
    }
  },[news,news1,news2])
  return (
    <section className="TEST">
     
      
      {isLoading ?
      <div className="TEST" style={{display:"flex",flexDirection:"column"}}>
        <div><Navbar /></div>
        <div style={{marginTop:"20%",marginBottom:"10%"}}><AvhLoader /></div>
        <div><Footer /></div>
      </div>
     
        :
      <>
      <Navbar />
      {news !== '' ? <NewsCenterHome whiteTitle={news.whiteTitle} greenTitle={news.greenTitle} coverImage={news.coverImage}/> : <></>}
      {news !== '' ? <AuthorInfo authorName={news.authorName} authorImage={news.authorImage}/> :<></>}
      {news !== '' ? <ImageLargeTextt Animated largeImage={news.largeImage} newsBody={news.newsBody} />:<></>}
      <Grid container xs={12} justifyContent="center" alignItems="center">
        <Grid item lg={6} md={6} sm={5}>
        {news1 !== ''?<ImgTextBelow
            Animated
            titleOne={news1.whiteTitle}
            titleTwo={news1.greenTitle}
            desc={news1.description}
            image={news1.image}
            link={news1.link}
          />:<></>}
        </Grid>

        <Grid item lg={6} md={6} sm={5}>
        {news2 !== '' ?<ImgTextBelow
            Animated
            titleOne={news2.whiteTitle}
            titleTwo={news2.greenTitle}
            desc={news2.description}
            image={news2.image}
            link={news2.link}
          />:<></>}
        </Grid>
      </Grid>
      <Footer />
      </>}
      
    </section>
  );
}
const mapStateToProps = (state) =>{
  return{
      news: state.AvhNewsR.news,
      news1: state.AvhNewsR.news1,
      news2: state.AvhNewsR.news2,
  };
}


const mapDispatchToProps = (dispatch) =>{
  return{ 
      getAvhNews: ()=> dispatch(AvhNewsActionCreators.getAvhNews()),
      getNews: ()=> dispatch(AvhNewsActionCreators.getNews())    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondNews)
