import "./TextImgImg.css";
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useMediaQuery } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import { TextImgImgData } from "./TextImgImgData";
import { Container } from "@material-ui/core";

const TextImgImg = ({ Animated = false }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const mobileSize = useMediaQuery("(max-width: 750px)");
  const reverseContent = useMediaQuery("(max-width: 600px)");
  return (
    <section className="textimgimg">
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={11}
          sm={6}
          style={{
            paddingLeft: mobileSize
              ? reverseContent
                ? "0px"
                : "20px"
              : "55px",
          }}
          data-aos={Animated ? "slide-right" : ""}
          data-aos-duration={Animated ? "3000" : ""}
        >
          <div className="text-cont">
            <p className="title1">{TextImgImgData.titleOne}</p>
            <p className="title2">{TextImgImgData.titleTwo}</p>
            <p className="desc">{TextImgImgData.desc}</p>
          </div>
        </Grid>
        <Grid
          item
          xs={11}
          sm={6}
          style={{
            padding: mobileSize ? (reverseContent ? "0px" : "20px") : "40px",
          }}
          data-aos={Animated ? "zoom-in" : ""}
          data-aos-duration={Animated ? "2000" : ""}
        >
          <div className="img-cont">
            <img src={TextImgImgData.image1} alt="img" />
            <img src={TextImgImgData.image2} alt="img" />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};
export default TextImgImg;
