import React from "react";
import { Route, Switch, Redirect } from "react-router";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Business from "./Pages/Business/BusinessesPages/BHome/Business";
import Careers from "./Pages/Careers/CareersPage/Careers";
import SeniorAccount from "./Pages/Careers/SeniorAccountPage/SeniorAccount";
import SeniorArt from "./Pages/Careers/SeniorAccountPage/SeniorArt";
import SeniorWriter from "./Pages/Careers/SeniorAccountPage/SeniorWriterT";
import Invest from "./Pages/Invest/Invest";
import Contact from "./Pages/Contact/Contact";
import Aviation from "./Pages/Business/BusinessesPages/Aviation/Aviation";
import BankingAndFinance from "./Pages/Business/BusinessesPages/BankingAndFinance/BankingAndFinance";
import RealState from "./Pages/Business/BusinessesPages/RealState/RealState";
import Insurance from "./Pages/Business/BusinessesPages/Insurance/Insurance";
import Pharmaceutical from "./Pages/Business/BusinessesPages/Pharmaceutical/Pharmaceutical";
import Startup from "./Pages/Business/BusinessesPages/Startup/Startup";
import News from "./Pages/News/News";
import FacilitiesManagement from "./Pages/Business/BusinessesPages/FacilitiesManagement/FacilitiesManagement";
import Government from "./Pages/Business/BusinessesPages/Government/Government";
import LogisticsAndTransport from "./Pages/Business/BusinessesPages/LogisticsAndTransport/LogisticsAndTransport";
import Retail from "./Pages/Business/BusinessesPages/Retail/Retail";
import Technology from "./Pages/Business/BusinessesPages/Technology/Technology";
import Telecommunications from "./Pages/Business/BusinessesPages/Telecommunications/Telecommunications";
import Travel from "./Pages/Business/BusinessesPages/Travel/Travel";
import Education from "./Pages/Business/BusinessesPages/Education/Education";
import BusinessLogistics from "./Pages/Business/BusinessesPages/BusinessHome/BusinessLogistics";
import HealthCare from "./Pages/Business/BusinessesPages/HealthCare/HealthCare";
import SecondNews from "./Pages/News/SecondNews/SecondNews";
import Test from "./Pages/Test"

function App() {
  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about-us" component={About} />
        <Route path="/news-center" component={News} />
        <Route path="/business" component={Business} />
        <Route path="/careers" component={Careers} />
        <Route path="/seniorAccount" component={SeniorAccount} />
        <Route path="/seniorArt" component={SeniorArt} />
        <Route path="/seniorWriter" component={SeniorWriter} />
        <Route path="/invest" component={Invest} />
        <Route path="/contact-us" component={Contact} />
        <Route path="/aviation" component={Aviation} />
        <Route path="/banking-and-finance" component={BankingAndFinance} />
        <Route path="/health-care" component={HealthCare} />
        <Route path="/real-estate" component={RealState} />
        <Route path="/insurance" component={Insurance} />
        <Route path="/pharmaceutical" component={Pharmaceutical} />
        <Route path="/startup" component={Startup} />
        <Route path="/AssestManagement" component={BusinessLogistics} />
        <Route path="/education" component={Education} />
        <Route path="/facilitateManagement" component={FacilitiesManagement} />
        <Route path="/Government" component={Government} />
        <Route path="/LogisticAndTransport" component={LogisticsAndTransport} />
        <Route path="/retail" component={Retail} />
        <Route path="/technology" component={Technology} />
        <Route path="/telecommunications" component={Telecommunications} />
        <Route path="/travel" component={Travel} />
        <Route path="/news" component={SecondNews} />
        <Route path="/test" component={Test} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
