import { Grid , makeStyles} from '@material-ui/core'
import React from 'react'
// import TextImgImgBigger from '../../../components/TextImgImgBigger/TextImgImgBigger'

import { ImgTextData, TextImgsData,EducationData} from './EducationData'
import './Education.css'
import TextImgImgBigger from '../../../../components/TextImgImgBigger/TextImgImgBigger'
import ImgTextText from '../../../../components/ImgTextText/ImgTextText'
import BusinessPages from '../../../../components/BusinessPages/BusinessPages'

const useStyles=makeStyles((theme)=>({


  imgText: {
      [theme.breakpoints.up("xs")]:{
        marginTop:"25px"
      },

      [theme.breakpoints.up("sm")]:{
        marginTop:"-70px"
      },

      [theme.breakpoints.up("md")]:{
        marginTop:"-100px"
      },

      [theme.breakpoints.up("lg")]:{
        marginTop:"-170px"
      },
  },


  parentTextImage: {
      [theme.breakpoints.up("xs")]:{
        marginTop:"75px",marginBottom:"15px"
      },

      [theme.breakpoints.up("sm")]:{
        marginTop:"65px",marginBottom:"75px"
      },

      [theme.breakpoints.up("md")]:{
        marginTop:"85px",marginBottom:"100px"
      },

  },


}));

function Education() {

  const classes= useStyles();

  return (
    <Grid>
      <BusinessPages
        whatwedo={EducationData.whatwedo}
        WhiteTitle={EducationData.WhiteTitle}
        // AquaTitle={BusinessData.AquaTitle}
        // description={BusinessData.description}
        BusinessImage={EducationData.BusinessImage}
      >
      <Grid item xs={12} className={classes.imgText}>
        <ImgTextText Animated
         title={ImgTextData.title}
         TextContent={ImgTextData.TextContent}
         img={ImgTextData.ImageText} />
      </Grid>

      <Grid item xs={12} className={classes.parentTextImage} id="imagetextttt" >
        <TextImgImgBigger Animated 
          title={TextImgsData.title2}
          TextContent={TextImgsData.TextContent2}
          img={TextImgsData.TextImage}
        />
      </Grid>
      </BusinessPages>
    </Grid>

  )
}

export default Education
