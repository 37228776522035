import { Grid } from "@material-ui/core";
import React from "react";
import BusinessPages from "../../../../../src/components/BusinessPages/BusinessPages";
import LargeImageText from "../../../../components/LargeImageText/LargeImageText";
import { RealStateData, LargeImageTextData } from "./RealStateData";
import "./Realstate.css";

function RealState() {
  return (
    <Grid>
      <BusinessPages
        whatwedo={RealStateData.whatwedo}
        WhiteTitle={RealStateData.WhiteTitle}
        AquaTitle={RealStateData.AquaTitle}
        BusinessImage={RealStateData.BusinessImage}
      >
        <Grid item xs={12} className="realstate-imgtext">
          <LargeImageText
            title={LargeImageTextData.title}
            desc1={LargeImageTextData.desc1}
            desc2={LargeImageTextData.desc2}
            Image={LargeImageTextData.Image}
          />
        </Grid>
      </BusinessPages>
    </Grid>
  );
}

export default RealState;
