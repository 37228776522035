import im01 from "./images/01.png";
import im02 from "./images/02.png";
import im03 from "./images/03.png";
import im04 from "./images/04.png";
import im05 from "./images/05.png";
import im06 from "./images/06.png";
import im07 from "./images/07.png";
import im08 from "./images/08.png";
import im09 from "./images/09.png";
import im10 from "./images/10.png";
import im11 from "./images/11.png";
import im12 from "./images/12.png";
import im13 from "./images/13.png";
import im14 from "./images/14.png";
import im15 from "./images/15.png";
import im16 from "./images/16.png";
import { Container, Grid } from "@material-ui/core";
import ServiceCardBusiness from "../ServiceCardBusiness/ServiceCardBusiness";

function AllCardsBusiness() {
  return (
    <Container>
      <Grid container spacing={6}>
        <ServiceCardBusiness
          icon={im01}
          title="Asset Management"
          index={1}
          url="/AssestManagement"
        />
        <ServiceCardBusiness
          icon={im02}
          title="Aviation"
          index={2}
          url="/aviation"
        />
        <ServiceCardBusiness
          icon={im03}
          title="Banking & Finance"
          index={3}
          url="/banking-and-finance"
        />
        <ServiceCardBusiness
          icon={im04}
          title="Education"
          index={4}
          url="/education"
        />
        <ServiceCardBusiness
          icon={im05}
          title="Facilities Management"
          index={5}
          url="/facilitateManagement"
        />
        <ServiceCardBusiness
          icon={im06}
          title="Real Estate"
          index={6}
          url="/real-estate"
        />
        <ServiceCardBusiness
          icon={im07}
          title="Government"
          index={7}
          url="/Government"
        />
        <ServiceCardBusiness
          icon={im08}
          title="Health Care"
          index={8}
          url="/health-care"
        />
        <ServiceCardBusiness
          icon={im09}
          title="Insurance"
          index={9}
          url="/insurance"
        />
        <ServiceCardBusiness
          icon={im10}
          title="Logistics & Transport"
          index={10}
          url="/LogisticAndTransport"
        />
        <ServiceCardBusiness
          icon={im11}
          title="Pharmaceutical"
          index={11}
          url="/pharmaceutical"
        />
        <ServiceCardBusiness
          icon={im12}
          title="Retail"
          index={12}
          url="/retail"
        />
        <ServiceCardBusiness
          icon={im13}
          title="Start Ups"
          index={13}
          url="/startup"
        />
        <ServiceCardBusiness
          icon={im14}
          title="Technology"
          index={14}
          url="/technology"
        />
        <ServiceCardBusiness
          icon={im15}
          title="Telecommunications"
          index={15}
          url="telecommunications"
        />
        <ServiceCardBusiness
          icon={im16}
          title="Travel & Tourism"
          index={16}
          url="/travel"
        />
      </Grid>
    </Container>
  );
}
export default AllCardsBusiness;
