
import axios from 'axios';
import * as actionTypes from '../actions/actions';
import { server }from './constants'

export const getStartUpInfo = (name, value) => {
    return{
        type: actionTypes.StartUp.GET_StartUp_INFO,
        name: name,
        value: value
    }
}

export const StartUp = (companyName,businessName,contactEmail,contactFax,contactName,contactPhone,customerNumber,aboutYou,address,ideas,linkedin,nda,postcode,progress,presentation,vision,additionalMaterial,feasibilityStudy,businessPlan) => {
    return dispatch =>{
        dispatch(StartUpStart());
        var FD = new FormData();
        FD.append("companyName", companyName);
        FD.append("businessName", businessName);
        FD.append("contactEmail", contactEmail);
        FD.append("contactFax", contactFax);
        FD.append("contactName", contactName);
        FD.append("contactPhone", contactPhone);
        FD.append("customerNumber", customerNumber);
        FD.append("aboutYou", aboutYou);
        FD.append("address", address);
        FD.append("ideas", ideas);
        FD.append("linkedin", linkedin);
        FD.append("nda", nda);
        FD.append("postcode", postcode);
        FD.append("progress", progress);
        FD.append("presentation", presentation);
        FD.append("vision", vision);
        for(let i=0 ; i< additionalMaterial.length;i++){
            FD.append("additionalMaterial", additionalMaterial[i]);
            // FD.append("docs",docs[i]);
        }
        
        FD.append("feasibilityStudy", feasibilityStudy);
        FD.append("businessPlan", businessPlan);

            axios(
                {
                    method: "post",
                    url: server+"/avh/api/startup",
                    data: FD,
                    headers: { "Content-Type": "multipart/form-data" },
                }
                

            )
                .then(res=>{
                    
                    dispatch(StartUpEnd(res.data));
                         
                         
                        }).catch(err => {
                            console.log("Failed to Send your informations. Please try again later ...");
                        })                                                                                                                                        
                }
}

export const StartUpStart = () => {

    return{
        type: actionTypes.StartUp.StartUp_START
    }

}

export const StartUpEnd = (data) => {

    return{
        type: actionTypes.StartUp.StartUp_END,
        start: data
    }
}

