import { Grid, Link, makeStyles } from "@material-ui/core";
import React from "react";
import image from "./message.svg";
import "./careersTextComponent.css";

const useStyles = makeStyles((theme) => ({
  // parent:{

  //     [theme.breakpoints.up("xs")]:{
  //         display:"flex",
  //         alignItems:"center",
  //         flexDirection:"column"
  //     },

  //     [theme.breakpoints.up("sm")]:{
  //         display:"flex",
  //         alignItems:"center",
  //         flexDirection:"column",
  //         justifyContent:"center"
  //     },

  //     [theme.breakpoints.up("md")]:{
  //         display:"flex",
  //         alignItems:"center",
  //         justifyContent:"flex-end",
  //         flexDirection:"row"
  //     },

  // },

  parentDesc: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      opacity: "50%",
      fontSize: "13px",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      opacity: "50%",
      fontSize: "14px",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      opacity: "50%",
      fontSize: "14px",
    },
  },

  Ttile: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      fontSize: "14px",
      fontWeight: 500,
    },

    [theme.breakpoints.down("405")]: {
      color: "white",
      fontSize: "12px",
      fontWeight: 500,
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      fontSize: "20px",
      fontWeight: 500,
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      fontSize: "22px",
      fontWeight: 500,
    },
  },

  button: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      fontSize: "10px",
      fontWeight: 400,
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      fontSize: "14px",
      fontWeight: 400,
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      fontSize: "15px",
      fontWeight: 400,
    },

    [theme.breakpoints.up("lg")]: {
      color: "white",
      fontSize: "16px",
      fontWeight: 400,
    },
  },

  parentImage: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },

  imagee: {
    [theme.breakpoints.up("xs")]: {
      width: "23px",
      height: "23px",
    },

    [theme.breakpoints.up("sm")]: {
      width: "30px",
      height: "30px",
    },
  },

  parentt: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "-55px",
    },
  },
}));

function CreersTextComponent({ title, desc ,link}) {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      style={{ color: "white", display: "flex", justifyContent: "center" }}
    >
      <Grid item xs={10} className={classes.parentt}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          className="CAREERS"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px 20px",
          }}
        >
          <Grid
            container
            item
            xs={11}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={8} className={classes.Ttile}>
              {" "}
              {title}{" "}
            </Grid>
            <Grid item xs={2} className={classes.parentImage}>
              {" "}
              <img src={image} className={classes.imagee} />{" "}
            </Grid>
          </Grid>
          <Grid container item xs={11} className={classes.parentDesc}>
            {" "}
            {desc}{" "}
          </Grid>
          <Grid container item xs={11} className={classes.button}>
            <Link href={link} color="inherit" underline="always">
              Overview
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CreersTextComponent;
