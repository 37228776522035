import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  makeStyles,
  styled,
  TextField,
  CircularProgress
} from "@material-ui/core";
import { mergeClasses } from "@material-ui/styles";
import React, { useEffect } from "react";
import { InvestData } from "./InvestData";
import "./Invest.css";
import message from "./Images/message.svg";
import clearImage from "./Images/clearicon.jpg";
import InvestPage from "../../components/InvestPage/InvestPage";
import UploadImage from "./Images/UploadIcon.png";
import document from "./document.docx";
import { connect } from 'react-redux';
import * as StartUpActionCreators from '../../store/actionsCreators/startUpAction';

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
    opacity: "50%",
  },
});

const useStyles = makeStyles((theme) => ({
  BoxParent: {
    [theme.breakpoints.up("md")]: {
      // position: "absolute",
      width: "1200px",
      height: "1006px",
      // left: "120px",
      // top: "526px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      filter: " blur(8px)",
    },
  },

  parentofall: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "-150px",
      marginBottom: "30px",
    },

    [theme.breakpoints.up("md")]: {
      marginTop: "-350px",
      marginBottom: "30px",
    },

    [theme.breakpoints.between("960", "1200")]: {
      marginTop: "-350px",
    },

    [theme.breakpoints.up("lg")]: {
      marginTop: "-500px",
      marginBottom: "50px",
    },
  },

  ApplicationTxt: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      fontSize: "12px",
      fontWeight: 500,
      display: "flex",
      justifyContent: "flex-start",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      fontSize: "14px",
      fontWeight: 500,
      display: "flex",
      justifyContent: "flex-start",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      fontSize: "22px",
      fontWeight: 500,
      display: "flex",
      justifyContent: "flex-start",
    },
  },

  clearTxt: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      fontSize: "12px",
      opacity: "50%",
      fontWeight: 400,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      fontSize: "12px",
      opacity: "50%",
      fontWeight: 400,
      display: "flex",
      // justifyContent:"flex-end",
      alignItems: "center",
    },
  },

  input: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      wordBreak: "break-all",
      // borderBottom: '1px solid #808080',
      fontSize: "12px",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      wordBreak: "break-all",
      // borderBottom: '1px solid #808080',
      fontSize: "14px",
    },

    [theme.breakpoints.down("712")]: {
      color: "white",
      wordBreak: "break-all",
      // borderBottom: '1px solid #808080',
      fontSize: "12px",
    },

    [theme.breakpoints.down("628")]: {
      color: "white",
      wordBreak: "break-all",
      // borderBottom: '1px solid #808080',
      fontSize: "11px",
    },
  },

  inputlabel: {
    [theme.breakpoints.up("xs")]: {
      color: "white !important",
      fontSize: "14px",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white !important",
      fontSize: "16px",
    },

    [theme.breakpoints.down("712")]: {
      color: "white !important",
      fontSize: "14px",
    },

    [theme.breakpoints.down("628")]: {
      color: "white !important",
      fontSize: "13px",
    },

    [theme.breakpoints.up("md")]: {
      color: "white !important",
      fontSize: "18px",
    },
  },

  borderlabel: {
    color: "white !important",
  },

  parentUploadFolder: {
    [theme.breakpoints.up("xs")]: {
      color: "white !important",
      fontSize: "12px",
      color: "white",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white !important",
      fontSize: "13.5px",
      color: "white",
    },
  },

  parentCheckAndButton: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      flexDirection: "column-reverse",
    },

    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
  },

  parentButon: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      justifyContent: "center",
      marginTop: "10px",
      fontSize: "18px",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      justifyContent: "center",
      marginTop: "30px",
      fontSize: "20px",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      justifyContent: "flex-start",
      marginTop: "0px",
    },
  },

  parentCheckbox: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      paddingRight: "0px",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      justifyContent: "flex-end",
      paddingRight: "32px",
    },
  },

  parentLinkedin: {
    [theme.breakpoints.up("xs")]: {
      marginTop: "25px",
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
  },

  imagee: {
    [theme.breakpoints.up("xs")]: {
      width: "12px",
      height: "10px",
    },

    [theme.breakpoints.up("sm")]: {
      width: "12px",
      height: "10px",
    },
    [theme.breakpoints.up("md")]: {
      width: "12px",
      height: "10px",
    },

    [theme.breakpoints.up("lg")]: {
      width: "14px",
      height: "10px",
    },
  },

  hidefilee: {
    [theme.breakpoints.up("xs")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-20px",
      right: 0,
      top: "-30px",
      height: "60px",
      width: "270px",
    },

    [theme.breakpoints.down("300")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-10px",
      right: 0,
      top: "-30px",
      height: "60px",
      width: "200px",
    },

    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-150px",
      right: 0,
      top: "-30px",
      height: "60px",
      width: "500px",
    },

    [theme.breakpoints.between("600", "650")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-130px",
      right: 0,
      top: "-30px",
      height: "60px",
      width: "460px",
    },

    [theme.breakpoints.up("md")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-280px",
      right: 0,
      top: "-30px",
      height: "60px",
      width: "800px",
    },

    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-400px",
      right: 0,
      top: "-30px",
      height: "60px",
      width: "1000px",
    },
  },

  paragraphh: {
    [theme.breakpoints.down("300")]: {
      fontSize: "8px",
    },
  },

  recenter: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "100px",
    },
  },
}));

function Invest({
  companyName,
    businessName,
    contactEmail,
    contactFax,
    contactName,
    contactPhone,
    customerNumber,
    aboutYou,
    address,
    ideas,
    linkedin,
    nda,
    postcode,
    progress,
    presentation,
    vision,
    additionalMaterial,
    feasibilityStudy,
    businessPlan,
    loading,
    error,
    status,
    getStartUpInfo,
    StartUp
}) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetAll = () =>{
    getStartUpInfo("companyName",'');
    getStartUpInfo("businessName",'');
    getStartUpInfo("contactEmail",'');
    getStartUpInfo("contactFax",'');
    getStartUpInfo("contactName",'');
    getStartUpInfo("contactPhone",'');
    getStartUpInfo("customerNumber",'');
    getStartUpInfo("aboutYou",'');
    getStartUpInfo("address",'');
    getStartUpInfo("ideas",'');
    getStartUpInfo("linkedin",'');
    getStartUpInfo("nda",'');
    getStartUpInfo("postcode",'');
    getStartUpInfo("progress",'');
    getStartUpInfo("presentation",'');
    getStartUpInfo("vision",'');
    getStartUpInfo("additionalMaterial",'');
    getStartUpInfo("feasibilityStudy",'');
    getStartUpInfo("businessPlan",'');
    getStartUpInfo("loading",false);
    getStartUpInfo("error",'');
    getStartUpInfo("status",'');
  }

  useEffect(()=>{
    if(status === 'Success'){
      resetAll();
    }
  },[status])

  const [stateChooseFeasibility, setStateChooseFeasibility] = React.useState('upload your files or drag and drop here');
  const [stateChooseBusinessPlan, setStateChooseBusinessPlan] = React.useState('upload your files or drag and drop here');
  const [stateChoosePresentation, setStateChoosePresentation] = React.useState('upload your files or drag and drop here');
  const [stateChooseNda, setStateChooseNda] = React.useState('upload your files or drag and drop here');
  const [stateChooseAdd, setStateChooseAdd] = React.useState('upload your files or drag and drop here');

  // var filenames = "";
  const pressed = (e) => {
    var filenames = "";
    if(e.target.name === "additionalMaterial"){
      var arr = [];
        for(let i = 0; i< e.target.files.length; i++){
          arr.push(e.target.files[i]);
        }
      getStartUpInfo("additionalMaterial",arr);
    }else{
    getStartUpInfo(e.target.name,e.target.files[0]);
    }
    if(e.target.name === "feasibilityStudy"){
      setStateChooseFeasibility(e.target.files[0].name);
    }else

    if(e.target.name === "businessPlan"){
      setStateChooseBusinessPlan(e.target.files[0].name);
    }else

    if(e.target.name === "nda"){
      setStateChooseNda(e.target.files[0].name);
    }else

    if(e.target.name === "presentation"){
      setStateChoosePresentation(e.target.files[0].name);
    }else

    if(e.target.name === "additionalMaterial"){
      var addName = '';
      for(let i = 0; i< e.target.files.length; i++){
        if(i === e.target.files.length -1){
          addName += e.target.files[i].name
        }else{
        addName += e.target.files[i].name + ' ,'
        }
      }
     
      // setStateChooseAdd(e.target.files[0].name);
      setStateChooseAdd(addName);

    }

  };

  const handleOnChange = (e) =>{
    getStartUpInfo(e.target.name,e.target.value);
  }

  const submitStartUp = (e) =>{
    e.preventDefault();
    StartUp(companyName,businessName,contactEmail,contactFax,contactName,contactPhone,customerNumber,aboutYou,address,ideas,linkedin,nda,postcode,progress,presentation,vision,additionalMaterial,feasibilityStudy,businessPlan)
  }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <Grid>
      <InvestPage
        whatwedo={InvestData.invest}
        AquaTitle={InvestData.AquaTitle}
        description={InvestData.description}
        BusinessImage={InvestData.BusinessImage}
      >
        <form onSubmit={submitStartUp}>
          <Grid
            container
            item
            xs={12}
            style={{ justifyContent: "center" }}
            className={classes.parentofall}
          >
            {/* <Grid container item lg={12} className={classes.recenter}> */}
            <Grid
              container
              item
              xs={11}
              lg={11}
              className="investBox"
              spacing={4}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItem: "center",
              }}
            >
              <Grid
                container
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid
                  container
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid
                    item
                    xs={4}
                    sm={2}
                    md={1}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {" "}
                    <img src={message} width="50%" height="50%" />{" "}
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sm={8}
                    md={8}
                    className={classes.ApplicationTxt}
                  >
                    {" "}
                    Application From{" "}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  sm={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={10}
                    sm={11}
                    md={11}
                    spacing={1}
                    className={classes.clearTxt}
                  >
                    {" "}
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <img src={clearImage} className={classes.imagee} />
                    </Grid>
                    <button className="button-reset-b" type="reset" onClick={resetAll}>
                      clear all
                    </button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={12} style={{ display: "flex" }}>
                <Grid container item xs={6} sm={3}>
                  <CssTextField
                    label="Name of Company"
                    id="custom-css-outlined-input"
                    value={companyName}
                    name="companyName"
                    onChange={handleOnChange}
                    variant="standard"
                    required
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    style={{ width: "90%" }}
                    placeholder="john"
                  />
                </Grid>

                <Grid container item xs={6} sm={3}>
                  <CssTextField
                    label="Name of Business"
                    id="custom-css-outlined-input"
                    value={businessName}
                    name="businessName"
                    onChange={handleOnChange}
                    variant="standard"
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "90%" }}
                    placeholder="john"
                  />
                </Grid>

                <Grid item xs={6} sm={3} className={classes.parentLinkedin}>
                  <CssTextField
                    label="Address"
                    id="custom-css-outlined-input"
                    value={address}
                    name="address"
                    onChange={handleOnChange}
                    variant="standard"
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "90%" }}
                    placeholder="Street Example, 23-1"
                  />
                </Grid>

                <Grid item xs={6} sm={3} className={classes.parentLinkedin}>
                  <CssTextField
                    label="PostCode"
                    id="custom-css-outlined-input"
                    value={postcode}
                    name="postcode"
                    onChange={handleOnChange}
                    variant="standard"
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "90%" }}
                    placeholder="XXXXX"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={12} style={{ display: "flex" }}>
                <Grid container item xs={6} sm={3}>
                  <CssTextField
                    label="Contact Name"
                    id="custom-css-outlined-input"
                    value={contactName}
                    name="contactName"
                    onChange={handleOnChange}
                    variant="standard"
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "90%" }}
                    placeholder="john"
                  />
                </Grid>

                <Grid container item xs={6} sm={3}>
                  <CssTextField
                    label="Contact Phone"
                    id="custom-css-outlined-input"
                    value={contactPhone}
                    name="contactPhone"
                    onChange={handleOnChange}
                    variant="standard"
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "90%" }}
                    placeholder="+1 111 111-11-11"
                  />
                </Grid>

                <Grid item xs={6} sm={3} className={classes.parentLinkedin}>
                  <CssTextField
                    label="Contact Email"
                    id="custom-css-outlined-input"
                    value={contactEmail}
                    name="contactEmail"
                    onChange={handleOnChange}
                    variant="standard"
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "90%" }}
                    placeholder="example@email.com"
                  />
                </Grid>

                <Grid item xs={6} sm={3} className={classes.parentLinkedin}>
                  <CssTextField
                    label="Contact Fax"
                    id="custom-css-outlined-input"
                    variant="standard"
                    value={contactFax}
                    name="contactFax"
                    onChange={handleOnChange}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "90%" }}
                    placeholder="john"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} style={{ display: "flex" }}>
                <Grid container item xs={12} sm={6}>
                  <CssTextField
                    label="Customer Number"
                    id="custom-css-outlined-input"
                    variant="standard"
                    value={customerNumber}
                    name="customerNumber"
                    onChange={handleOnChange}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "95%" }}
                    placeholder="If you are an existing customer, please quote your account number"
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  className={classes.parentLinkedin}
                >
                  <CssTextField
                    label="Linkedin"
                    id="custom-css-outlined-input"
                    variant="standard"
                    value={linkedin}
                    name="linkedin"
                    onChange={handleOnChange}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "95%" }}
                    placeholder="LinkedIn URL"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} style={{ display: "flex" }}>
                <Grid container item xs={12}>
                  <CssTextField
                    label="Lets talk about your idea?"
                    id="custom-css-outlined-input"
                    value={ideas}
                    name="ideas"
                    onChange={handleOnChange}
                    variant="standard"
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "97.5%" }}
                    placeholder="What problem is your company solving?"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} style={{ display: "flex" }}>
                <Grid container item xs={12}>
                  <CssTextField
                    label="Your vision"
                    id="custom-css-outlined-input"
                    variant="standard"
                    value={vision}
                    name="vision"
                    onChange={handleOnChange}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    multiline
                    style={{ width: "97.5%" }}
                    placeholder="What is the vision of your company?"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} style={{ display: "flex" }}>
                <Grid container item xs={12}>
                  <CssTextField
                    label="Traction / progress"
                    id="custom-css-outlined-input"
                    variant="standard"
                    value={progress}
                    name="progress"
                    onChange={handleOnChange}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    multiline
                    style={{ width: "97.5%" }}
                    placeholder="What traction/progress have you made so far towards this vision? (Eg: product, customers, revenue, funds raised)"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} style={{ display: "flex" }}>
                <Grid container item xs={12}>
                  <CssTextField
                    label="About you"
                    id="custom-css-outlined-input"
                    variant="standard"
                    value={aboutYou}
                    name="aboutYou"
                    onChange={handleOnChange}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputlabel }}
                    focused
                    required
                    style={{ width: "97.5%" }}
                    placeholder="What should we know about you and your team?"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <p className="nda"> NDA</p>
                <a className="download" href={document} download>
                  Download the NDA and sign it before uploading it in the
                  section below.
                </a>
              </Grid>
              {/* one */}
              <Grid container xs={12} style={{ display: "flex" }} spacing={2}>
                <Grid container item xs={12} sm={6} style={{ display: "flex" }}>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.parentUploadFolder}
                  >
                    Feasibility study
                    <Grid
                      style={{
                        border: "1px dashed silver",
                        width: "97.5%",
                        opacity: 0.2,
                        height: "72px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Grid
                        className="upload-container"
                        style={{
                          // display: stateChooseFeasibility !== 'upload your files or drag and drop here' ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <input
                          type="file"
                          id="feasibilityStudy"
                          name="feasibilityStudy"
                          onChange={pressed}
                          // multiple
                          required
                          className={classes.hidefilee}
                        />

                        <img src={UploadImage} width="15px" height="15px" />
                        <p
                          className={classes.paragraphh}
                          id="feasibilityStudyT"
                          style={{ marginLeft: "10px" }}
                        >
                          {stateChooseFeasibility}
                          {/* upload your files or drag and drop here */}
                        </p>
                      </Grid>

                      <div
                        style={{ color: "white", opacity: "100%" }}
                        
                      >
                        
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  style={{ display: "flex" }}
                  spacing={2}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.parentUploadFolder}
                  >
                    Business plan
                    <Grid
                      style={{
                        border: "1px dashed silver",
                        width: "97.5%",
                        opacity: 0.2,
                        height: "72px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Grid
                        className="upload-container"
                        style={{
                          // display: stateChooseBusinessPlan !== 'upload your files or drag and drop here'? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <input
                          type="file"
                          id="businessPlan"
                          name="businessPlan"
                          onChange={pressed}
                          // multiple
                          required
                          className={classes.hidefilee}
                        />

                        <img src={UploadImage} width="15px" height="15px" />
                        <p
                          className={classes.paragraphh}
                          style={{ marginLeft: "10px" }}
                        >
                          {stateChooseBusinessPlan}
                          {/* upload your files or drag and drop here */}
                        </p>
                      </Grid>

                      <div
                        style={{ color: "white", opacity: "100%" }}
                        id="businessPlanT"
                      >
                        
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                style={({ display: "flex" }, { marginTop: "10px" })}
                spacing={2}
              >
                <Grid container item xs={12} sm={6} style={{ display: "flex" }}>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.parentUploadFolder}
                  >
                    Presentation
                    <Grid
                      style={{
                        border: "1px dashed silver",
                        width: "97.5%",
                        opacity: 0.2,
                        height: "72px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Grid
                        className="upload-container"
                        style={{
                          // display: stateChoosePresentation !== 'upload your files or drag and drop here' ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <input
                          type="file"
                          id="presentation"
                          name="presentation"
                          onChange={pressed}
                          // multiple
                          required
                          className={classes.hidefilee}
                        />

                        <img src={UploadImage} width="15px" height="15px" />
                        <p
                          className={classes.paragraphh}
                          style={{ marginLeft: "10px" }}
                        >
                          {stateChoosePresentation}
                          {/* upload your files or drag and drop here */}
                        </p>
                      </Grid>

                      <div
                        style={{ color: "white", opacity: "100%" }}
                        id="presentationT"
                      >
                       
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  style={{ display: "flex" }}
                  spacing={2}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.parentUploadFolder}
                  >
                    NDA
                    <Grid
                      style={{
                        border: "1px dashed silver",
                        width: "97.5%",
                        opacity: 0.2,
                        height: "72px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Grid
                        className="upload-container"
                        style={{
                          // display: stateChooseNda !== 'upload your files or drag and drop here' ? "flex" : "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="file"
                          id="nda"
                          name="nda"
                          onChange={pressed}
                          // multiple
                          required
                          className={classes.hidefilee}
                        />

                        <img src={UploadImage} width="15px" height="15px" />
                        <p
                          className={classes.paragraphh}
                          style={{ marginLeft: "10px" }}
                        >
                          {stateChooseNda}
                          {/* upload your files or drag and drop here */}
                        </p>
                      </Grid>

                      <div
                        style={{ color: "white", opacity: "100%" }}
                        id="ndaT"
                      >
                        
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} style={{ display: "flex" }}>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.parentUploadFolder}
                >
                  Additional Materials
                  <Grid
                    style={{
                      border: "1px dashed silver",
                      width: "97.5%",
                      opacity: 0.2,
                      height: "72px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Grid
                      className="upload-container"
                      style={{
                        // display: stateChooseAdd === 'upload your files or drag and drop here' ? "flex" : "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="file"
                        id="additionalMaterial"
                        name="additionalMaterial"
                        onChange={pressed}
                        multiple
                        required
                        className={classes.hidefilee}
                      />

                      <img src={UploadImage} width="15px" height="15px" />
                      <p
                        className={classes.paragraphh}
                        style={{ marginLeft: "10px" }}
                      >
                        {stateChooseAdd}
                        {/* upload your files or drag and drop here */}
                      </p>
                    </Grid>

                    <div
                      style={{ color: "white", opacity: "100%" }}
                      id="additionalMaterialT"
                    >
                      
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.parentCheckAndButton}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  className={classes.parentButon}
                >
                  {/* <Link href="#" color="inherit" underline="always">
                    submit
                  </Link> */}
                  <button  type="submit" className="submit-form">
                      { 
                        loading ? <CircularProgress style={{color:'#fff'}} size={35} /> : <>Submit</>
                      }
                  </button>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  className={classes.parentCheckbox}
                >
                  <label className="b-contain">
                    <span>
                      Would you like to join our Community newsletter?
                    </span>
                    <input type="checkbox" />
                    <div className="b-input"></div>
                  </label>
                </Grid>
              </Grid>
            </Grid>

            {/* </Grid> */}
          </Grid>
        </form>
      </InvestPage>
    </Grid>
  );
}
const mapStateToProps = (state) =>{
  return{
    companyName: state.StartUpR.companyName,
    businessName: state.StartUpR.businessName,
    contactEmail: state.StartUpR.contactEmail,
    contactFax: state.StartUpR.contactFax,
    contactName: state.StartUpR.contactName,
    contactPhone: state.StartUpR.contactPhone,
    customerNumber: state.StartUpR.customerNumber,
    aboutYou: state.StartUpR.aboutYou,
    address: state.StartUpR.address,
    ideas: state.StartUpR.ideas,
    linkedin: state.StartUpR.linkedin,
    nda: state.StartUpR.nda,
    postcode: state.StartUpR.postcode,
    progress: state.StartUpR.progress,
    presentation: state.StartUpR.presentation,
    vision: state.StartUpR.vision,
    additionalMaterial: state.StartUpR.additionalMaterial,
    feasibilityStudy: state.StartUpR.feasibilityStudy,
    businessPlan: state.StartUpR.businessPlan,
    loading: state.StartUpR.loading,
    error: state.StartUpR.error,
    status: state.StartUpR.status,
  };
}


const mapDispatchToProps = (dispatch) =>{
  return{
      getStartUpInfo : (name,value) => dispatch(StartUpActionCreators.getStartUpInfo(name,value)), 
      StartUp: (companyName,businessName,contactEmail,contactFax,contactName,contactPhone,customerNumber,aboutYou,address,ideas,linkedin,nda,postcode,progress,presentation,vision,additionalMaterial,feasibilityStudy,businessPlan)=> dispatch(StartUpActionCreators.StartUp(companyName,businessName,contactEmail,contactFax,contactName,contactPhone,customerNumber,aboutYou,address,ideas,linkedin,nda,postcode,progress,presentation,vision,additionalMaterial,feasibilityStudy,businessPlan))
      
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invest)

//
