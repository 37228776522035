import { Grid } from "@material-ui/core";
import React from "react";
import BusinessPages from "../../../../../src/components/BusinessPages/BusinessPages";
import LargeImageText from "../../../../components/LargeImageText/LargeImageText";
import {
  BankingAndFinanceData,
  LargeImageTextData,
} from "./BankingAndFinanceData";

function BankingAndFinance() {
  return (
    <Grid>
      <BusinessPages
        whatwedo={BankingAndFinanceData.whatwedo}
        WhiteTitle={BankingAndFinanceData.WhiteTitle}
        AquaTitle={BankingAndFinanceData.AquaTitle}
        BusinessImage={BankingAndFinanceData.BusinessImage}
      >
        <Grid item xs={12}>
          <LargeImageText
            title={LargeImageTextData.title}
            desc1={LargeImageTextData.desc1}
            desc2={LargeImageTextData.desc2}
            Image={LargeImageTextData.Image}
          />
        </Grid>
      </BusinessPages>
    </Grid>
  );
}

export default BankingAndFinance;
