import "./AboutAVH.css";
import { AboutAVHData } from "./AboutAVHData";
import background from "./images/background.png";
function AboutAVH(props) {
  return (
    <section className="AboutAVH">
      <img src={background}></img>
      <div className="about-overlay">
        <p className="pretitle">{AboutAVHData.pretitle}</p>
        <p className="title1">{AboutAVHData.titleOne} </p>
        <p className="title2">{AboutAVHData.titleTwo} </p>
        <p className="desc">
          <span>{AboutAVHData.descOne}</span> {AboutAVHData.descTwo}
        </p>
      </div>
    </section>
  );
}
export default AboutAVH;
