import ServiceCard from "../ServiceCard/ServiceCard";
import "./AllCards.css";
import im01 from "./sc/1.JPG";
import im02 from "./sc/2.JPG";
import im03 from "./sc/3.JPG";
import im04 from "./sc/4.JPG";
import im05 from "./sc/5.JPG";
import im06 from "./sc/6.JPG";
import im07 from "./sc/7.JPG";
import im08 from "./sc/8.JPG";
import im09 from "./sc/9.JPG";
import im10 from "./sc/10.JPG";
import im11 from "./sc/11.JPG";
import im12 from "./sc/12.JPG";
import im13 from "./sc/13.JPG";
import im14 from "./sc/14.JPG";
import im15 from "./sc/15.JPG";
import im16 from "./sc/16.JPG";
import { Container, Grid } from "@material-ui/core";

function AllCards() {
  return (
    <Container>
      <Grid container spacing={6}>
        <ServiceCard
          icon={im01}
          title="Asset Management"
          index={1}
          url="/AssestManagement"
        />
        <ServiceCard icon={im02} title="Aviation" index={2} url="/aviation" />
        <ServiceCard
          icon={im03}
          title="Banking & Finance"
          index={3}
          url="/banking-and-finance"
        />
        <ServiceCard icon={im04} title="Education" index={4} url="/education" />
        <ServiceCard
          icon={im05}
          title="Facilities Management"
          index={5}
          url="/facilitateManagement"
        />
        <ServiceCard
          icon={im06}
          title="Real Estate"
          index={6}
          url="/real-estate"
        />
        <ServiceCard
          icon={im07}
          title="Government"
          index={7}
          url="/Government"
        />
        <ServiceCard
          icon={im08}
          title="Health Care"
          index={8}
          url="/health-care"
        />
        <ServiceCard icon={im09} title="Insurance" index={9} url="/insurance" />
        <ServiceCard
          icon={im10}
          title="Logistics & Transport"
          index={10}
          url="/LogisticAndTransport"
        />
        <ServiceCard
          icon={im11}
          title="Pharmaceutical"
          index={11}
          url="/pharmaceutical"
        />
        <ServiceCard icon={im12} title="Retail" index={12} url="/retail" />
        <ServiceCard icon={im13} title="Start Ups" index={13} url="/startup" />
        <ServiceCard
          icon={im14}
          title="Technology"
          index={14}
          url="/technology"
        />
        <ServiceCard
          icon={im15}
          title="Telecommunications"
          index={15}
          url="/telecommunications"
        />
        <ServiceCard
          icon={im16}
          title="Travel & Tourism"
          index={16}
          url="/travel"
        />
      </Grid>
    </Container>
  );
}
export default AllCards;
