import { Grid } from "@material-ui/core";
import "./ServiceCardBusiness.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useHistory } from "react-router";

function ServiceCardBusiness(props) {
  const history = useHistory();
  const handleClick = (test) => {
    history.push({
      pathname: test,
    });
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      key={props.title}
      data-aos={props.index % 2 === 0 ? "zoom-in" : "zoom-out"}
      data-aos-duration="2000"
    >
      <div className="card-cont-bus" onClick={() => handleClick(props.url)}>
        <div className="content-container-bus">
          <div>
            <img className="card-img-bus" src={props.icon} alt=""></img>
          </div>
          <div className="card-title-bus">
            <p>{props.title}</p>
          </div>
          <div>
            <button className="learn-more">Learn more</button>
          </div>
        </div>
      </div>
    </Grid>
  );
}
export default ServiceCardBusiness;
