
import axios from 'axios';
import * as actionTypes from '../actions/actions';
import { server }from './constants'

export const getNews = () => {
    
    return dispatch =>{       
        var link = server+'/avh/api/getTopNews';
       axios.get(link)
            .then(res=>{
                    console.log(res.data)
                    dispatch(getNewsEnd(res.data));
                                
            }).catch(err => {
                console.log("Something wrong while getting News ...");
            })
        
    }
}

export const getNewsEnd = (data) => {
    var news1 = {}
    var news2 = {}
    var news3 = {}
    var news4 = {}
    var news5 = {}
    for(let i = 0 ; i< data.length ; i++){
        if(data[i].position === 1){
            news1 = data[i]
        }else if(data[i].position === 2){
            news2 = data[i]
        }else if(data[i].position === 3){
            news3 = data[i]
        }else if(data[i].position === 4){
            news4 = data[i]
        }else if(data[i].position === 5){
            news5 = data[i]
        }
    }
    

    return{
        type: actionTypes.News.GET_NEWS,
        news1: news1,
        news2: news2,
        news3: news3,
        news4: news4,
        news5: news5
    }
}

export const getAvhNews = () => {
    
    return dispatch =>{       
        var link = server+'/avh/api/avhNews';
       axios.get(link)
            .then(res=>{
                    console.log(res.data)
                    dispatch(getAvhNewsEnd(res.data));
                                
            }).catch(err => {
                console.log("Something wrong while getting News ...");
            })
        
    }
}

export const getAvhNewsEnd = (data) => {
       
    return{
        type: actionTypes.News.GET_AVH_NEWS,
        newsA: {img: data.largeImage,
            titleOne: data.whiteTitle,
            titleTwo: data.greenTitle,
            desc: data.description}
    }
}