import img from './TechnologyImages/ImageTechnology.png'
import img2 from './TechnologyImages/TextImageTechnology.png'

export const TechnologyData = {

    whatwedo: "WHAT WE DO",
    WhiteTitle: "TECHNOLOGY",
    description: "AVH provides the complete range of IT solutions to help businesses thrive now and into the future. We guide clients just starting out on their digital transformation journey, as well as scaling up existing digital strategies for accelerated growth. AVH uses robotics, big data analytics, IoT, AI and blockchain to drive distinct competitive advantage for businesses in any industry.",
    BusinessImage: img,
};

export const TextImgsTechnology = {

    title: "Advanced Digital Platform for Technology Services",
    TextContents: "NUSOFT is an advanced digital technology marketplace that connects buyers and sellers of technology products and services, all in one place. It enables fast insightful search and secure, easy transactions for the right technology solutions at competitive prices.",
    Image: img2,
};
