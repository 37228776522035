import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import BusinessPages from "../../../../../src/components/BusinessPages/BusinessPages";
import ImgTextText from "../../../../components/ImgTextText/ImgTextText";

import { HealthCareData, ImageTextData } from "./HealthCareData";

function HealthCare() {
  return (
    <Grid>
      <BusinessPages
        whatwedo={HealthCareData.whatwedo}
        WhiteTitle={HealthCareData.WhiteTitle}
        BusinessImage={HealthCareData.BusinessImage}
      >
        <Grid item xs={12}>
          <ImgTextText
            Animated
            title={ImageTextData.title}
            TextContent={ImageTextData.desc}
            img={ImageTextData.Image}
          />
        </Grid>
      </BusinessPages>
    </Grid>
  );
}

export default HealthCare;
