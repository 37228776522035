import React, { useEffect } from "react";
import AllCards from "../../components/AllCards/AllCards";
import CardsHeading from "../../components/CardsHeading/CardsHeading";
import Footer from "../../components/Footer/Footer";
import ImgText from "../../components/ImgText/ImgText";
import { ImgTextData } from "../../components/ImgText/ImgTextData";
import Navbar from "../../components/Navbar/Navbar";
import { dataSlider } from "../../components/Slider/dataSlider";
import Slider from "../../components/Slider/Slider";
import TextImgImg from "../../components/TextImgImg/TextImgImg";
import "./Home.css";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <Navbar />
      <div className="slider-home">
        <Slider slides={dataSlider} />
      </div>
      <TextImgImg Animated />
      <ImgText Animated />
      <CardsHeading />
      <AllCards Animated />
      <Footer />
    </div>
  );
};
export default Home;
