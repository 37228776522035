
import axios from 'axios';
import * as actionTypes from '../actions/actions';
import { server }from './constants'

export const getSendInfo = (name, value) => {
    return{
        type: actionTypes.SendCv.GET_SEND_INFO,
        name: name,
        value: value
    }
}

export const SendCv = (fname,lname,headLine,phone,email,address,coverLetter,resume) => {
    return dispatch =>{
        dispatch(SendCvStart());
        var FD = new FormData();
        FD.append("firstName", fname);
        FD.append("lastName", lname);
        FD.append("headLine", headLine);
        FD.append("phone", phone);
        FD.append("email", email);
        FD.append("address", address);
        FD.append("coverLetter", coverLetter);
        FD.append("resume", resume);

            axios(
                {
                    method: "post",
                    url: server+"/avh/api/careers",
                    data: FD,
                    headers: { "Content-Type": "multipart/form-data" },
                }
                

            )
                .then(res=>{
                    
                    dispatch(SendCvEnd(res.data));
                         
                         
                        }).catch(err => {
                            console.log("Failed to Send your informations. Please try again later ...");
                        })                                                                                                                                        
                }
}

export const SendCvStart = () => {

    return{
        type: actionTypes.SendCv.SEND_START
    }

}

export const SendCvEnd = (data) => {

    return{
        type: actionTypes.SendCv.SEND_END,
        sent: data
    }
}

