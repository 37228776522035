import { Grid , makeStyles} from '@material-ui/core'
import React, {useEffect} from 'react'
import BusinessPages from '../../../../components/BusinessPages/BusinessPages';
import TextImgImgBigger from '../../../../components/TextImgImgBigger/TextImgImgBigger';
// import BusinessPages from '.././../../../src/components/BusinessPages/BusinessPages'
// import TextImgImgBigger from '../../../../src/components/TextImgImgBigger/TextImgImgBigger'
import { LogisticsAndTransportt,TextImgsData } from './LogisticsAndTransportData'
import './Logistics.css' 

const useStyles=makeStyles((theme)=>({


  imgText: {
    [theme.breakpoints.up("xs")]:{
      marginTop:"140px",
      marginBottom:"70px"
    },

    [theme.breakpoints.down("380")]:{
      marginTop:"150px"
    },
    
    [theme.breakpoints.down("370")]:{
      marginTop:"180px"
    },

    [theme.breakpoints.down("300")]:{
      marginTop:"200px"
    },

      [theme.breakpoints.up("sm")]:{
        marginTop:"75px",
        marginBottom:"50px"
      },

      [theme.breakpoints.between("600","750")]:{
        marginTop:"100px",
        marginBottom:"50px"
      },

      [theme.breakpoints.between("600","630")]:{
        marginTop:"130px",
        marginBottom:"50px"
      },

      [theme.breakpoints.up("md")]:{
        marginTop:"-35px",
        marginBottom:"280px"
      },

      [theme.breakpoints.between("959","1050")]:{
        marginTop:"120px",
        marginBottom:"50px"
      },


  },

}));

function LogisticsAndTransport() {

  const classes= useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 


  return (
    <Grid>
      <BusinessPages
        whatwedo={LogisticsAndTransportt.whatwedo}
        WhiteTitle={LogisticsAndTransportt.WhiteTitle}
        AquaTitle={LogisticsAndTransportt.AquaTitle}
        description={LogisticsAndTransportt.description}
        BusinessImage={LogisticsAndTransportt.BusinessImage}
      >


      <Grid item xs={12} className={classes.imgText} id="imagetext" >
        <TextImgImgBigger Animated 
          title={TextImgsData.title}
          TextContent={TextImgsData.TextContent}
          img={TextImgsData.Image}
        />
      </Grid>
      </BusinessPages>
    </Grid>

  )
}

export default LogisticsAndTransport
