import React, { useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import CardsHeading from "../../../../components/CardsHeading/CardsHeading";
import ImgText from "../../../../components/ImgText/ImgText";
import { dataSlider } from "../../../../components/Slider/dataSlider";
import Slider from "../../../../components/Slider/Slider";
import TextImgImg from "../../../../components/TextImgImg/TextImgImg";
import AllCardsBusiness from "../../../../components/AllCardsBusiness/AllCardsBusiness";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";

const Business = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <Navbar />
      <div className="slider-home">
        <Slider slides={dataSlider} />
      </div>
      <TextImgImg Animated />
      <ImgText Animated />
      <CardsHeading />
      <AllCardsBusiness Animated />
      <Footer />
    </div>
  );
};
export default Business;
