import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import "./ContactUsInfo.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  ContactUsInfoData1,
  ContactUsInfoData2,
  ContactUsInfoData3,
} from "./ContactUsInfoData";

function ContactUsInfo() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="ContactUsInfo">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        // direction={reverseContent ? "column-reverse" : null}
        // data-aos={Animated ? "zoom-in" : ""}
        // data-aos-duration={Animated ? "3000" : ""}
      >
        <Grid item xs={11}>
          {/* <img src={image} alt="" width="100%" /> */}
          <div className="location">
            <div className="location-icon">
              <img src={ContactUsInfoData1.icon} alt="" />
            </div>
            <div className="title t1">{ContactUsInfoData1.title1}</div>
            <div className="title t2">{ContactUsInfoData1.title2}</div>
            <div className="title t3">{ContactUsInfoData1.title3}</div>
          </div>
        </Grid>

        <Grid container xs={11} className="callAndEmail">
          <Grid item xs={5}>
            <div className="email">
              <div className="email-icon">
                <img src={ContactUsInfoData2.icon} alt="" />
              </div>
              <div className="title t1">{ContactUsInfoData2.title1}</div>
              <div className="title t2">{ContactUsInfoData2.title2}</div>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className="call">
              <div className="call-icon">
                <img src={ContactUsInfoData3.icon} alt="" />
              </div>
              <div className="title t1">{ContactUsInfoData3.title1}</div>
              <div className="title t2">{ContactUsInfoData3.title2}</div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
}
export default ContactUsInfo;
