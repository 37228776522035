import img from "./images/img.png";
import img1 from "./images/img2.png";

export const InsuranceData = {
  whatwedo: "WHAT WE DO",
  WhiteTitle: "INSURANCE",
  description:
    "AVH is a leading provider of insurance products and services. We combine our strength in the sector with innovative technology to deliver superior solutions for all types of insurance.",
  BusinessImage: img,
};
export const TextImageData = {
  title: "Advanced Digital Insurance Platform",
  desc: "NURANCE is an agile digital marketplace that connects reinsurance, insurance brokers, insurance companies and end customers. It automates information flow and key processes such as customer acquisition, underwriting and claims management. NURANCE features advanced tools to give customers a seamless experience in finding the best fit insurance product.",
  Image: img1,
};
