import { Grid , makeStyles} from '@material-ui/core'
import React, {useEffect} from 'react'
import BusinessPages from '../../../../components/BusinessPages/BusinessPages';
import TextImgImgBigger from '../../../../components/TextImgImgBigger/TextImgImgBigger';
import { TextImgsTravel, TravelData } from './TravelData';
import './Travel.css'


const useStyles=makeStyles((theme)=>({


  imgText: {
      [theme.breakpoints.up("xs")]:{
        marginTop:"100px",
        marginBottom:"70px"
      },

      [theme.breakpoints.down("380")]:{
        marginTop:"110px"
      },
      
      [theme.breakpoints.down("370")]:{
        marginTop:"160px"
      },

      [theme.breakpoints.up("sm")]:{
        marginTop:"30px",
        marginBottom:"70px"
      },

      [theme.breakpoints.up("md")]:{
        marginTop:"-50px",
        marginBottom:"40px"
      },
    
      
  },

}));

function Telecommunications() {

  const classes= useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <Grid>
      <BusinessPages
        whatwedo={TravelData.whatwedo}
        WhiteTitle={TravelData.WhiteTitle}
        AquaTitle={TravelData.AquaTitle}
        description={TravelData.description}
        BusinessImage={TravelData.BusinessImage}
      >


      <Grid item xs={12} className={classes.imgText} id="imagetextTravel">
        <TextImgImgBigger Animated 
          title={TextImgsTravel.title}
          TextContent={TextImgsTravel.TextContents}
          img={TextImgsTravel.Image}
        />
      </Grid>
      </BusinessPages>
    </Grid>

  )
}

export default Telecommunications
