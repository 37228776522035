import * as actionTypes from '../actions/actions';

const initialState = {
    companyName: '',
    businessName: '',
    contactEmail: '',
    contactFax: '',
    contactName: '',
    contactPhone: '',
    customerNumber: '',
    aboutYou: '',
    address: '',
    ideas: '',
    linkedin: '',
    nda: '',
    postcode: '',
    progress: '',
    presentation: '',
    vision: '',
    additionalMaterial: [],
    feasibilityStudy: '',
    businessPlan: '',
    loading: false,
    error: '',
    status: ''  
}

const StartUpR =  (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.StartUp.GET_StartUp_INFO :
            return { ...state, [action.name] : action.value  }

        case actionTypes.StartUp.StartUp_START :
            return { ...state,loading: true}

        case actionTypes.StartUp.StartUp_END :
            return { ...state,loading: false, status: action.start}  
                    
        default:
            return state
    }
}

export default StartUpR;