
import img from './GovernmentImages/GovernmentImg.png'
import img2 from './GovernmentImages/ImgText.png'

export const GovernmentData = {

    whatwedo: "WHAT WE DO",
    WhiteTitle: "GOVERNMENT",
    BusinessImage: img,
};

export const ImgTextData = {

    title: "Advanced Digital Solutions for Government",
    TextContent: "Governments around the world have been forced to deploy more digital methods of service delivery; initially by technology trends such as AI, machine learning and big data, and now accelerated by COVID-19. AVH solutions help governments around the world to adapt with fully integrated digital solutions that enable more streamlined, cost-effective processes.",
    ImageText: img2,
};
