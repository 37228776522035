import img from "./images/img.png";
import img1 from "./images/img2.png";

export const RealStateData = {
  whatwedo: "WHAT WE DO",
  WhiteTitle: "REAL",
  AquaTitle: "ESTATE",
  BusinessImage: img,
};
export const LargeImageTextData = {
  title: "Advanced Digital Platform for Real Estate",
  desc1:
    "AVH provides implementation of features that make the search for and delivery of real estate services much more seamless.",

  desc2:
    " NUMZ enable customers to compare and find real estate services and cheaper, give customer access to trusted and verified real state service providers, Provide greater customer reach for real estate service providers, and increase price and quality competitiveness in real estate sector.",
  Image: img1,
};
