import { Grid, Link, makeStyles, TextField, styled, CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import messageImg from "./Images/message.svg";
import clearImage from "./Images/clearicon.jpg";
// import UploadImage from "./Images/UploadIcon.png";
import "./ContactForm.css";
import { connect } from 'react-redux';
import * as ContactActionCreators from '../../store/actionsCreators/ContactActionCreator';
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
    opacity: "5%",
  },
});

const useStyles = makeStyles((theme) => ({
  BoxParent: {
    [theme.breakpoints.up("md")]: {
      width: "1200px",
      height: "1006px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      filter: " blur(8px)",
    },
  },

  ApplicationTxt: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      fontSize: "14px",
      fontWeight: 500,
      textAlign: "center",
    },

    [theme.breakpoints.down("347")]: {
      color: "white",
      fontSize: "10px",
      fontWeight: 500,
      textAlign: "center",
    },

    [theme.breakpoints.down("294")]: {
      color: "white",
      fontSize: "8px",
      fontWeight: 400,
      textAlign: "center",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      fontSize: "14px",
      fontWeight: 500,
      textAlign: "center",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      fontSize: "14px",
      fontWeight: 500,
      textAlign: "center",
    },
  },

  clearTxt: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      fontSize: "12px",
      opacity: "50%",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
    },

    [theme.breakpoints.down("292")]: {
      color: "white",
      fontSize: "10px",
      opacity: "50%",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      fontSize: "14px",
      opacity: "50%",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
    },
  },

  input: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      wordBreak: "break-all",
      borderBottom: "1px solid #808080",
      fontSize: "12px",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      wordBreak: "break-all",
      borderBottom: "1px solid #808080",
      fontSize: "14px",
      height: "50px",
    },

    [theme.breakpoints.down("712")]: {
      color: "white",
      wordBreak: "break-all",
      borderBottom: "1px solid #808080",
      fontSize: "12px",
    },

    [theme.breakpoints.down("628")]: {
      color: "white",
      wordBreak: "break-all",
      borderBottom: "1px solid #808080",
      fontSize: "11px",
    },
  },

  inputlabel: {
    [theme.breakpoints.up("xs")]: {
      color: "white !important",
      fontSize: "14px",
    },
    [theme.breakpoints.down("712")]: {
      color: "white !important",
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      color: "white !important",
      fontSize: "13px",
    },

    [theme.breakpoints.down("712")]: {
      color: "white !important",
      fontSize: "14px",
    },

    [theme.breakpoints.down("628")]: {
      color: "white !important",
      fontSize: "12px",
    },

    [theme.breakpoints.up("md")]: {
      color: "white !important",
      fontSize: "22px",
      fontWeight: 500,
    },
  },

  parentUploadFolder: {
    [theme.breakpoints.up("xs")]: {
      color: "white !important",
      fontSize: "12px",
      color: "white",
      display: "flex",
      justifyContent: "flex-start",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white !important",
      fontSize: "12px",
      color: "white",
      display: "flex",
      justifyContent: "flex-start",
    },
  },

  parentUploadFolder2: {
    [theme.breakpoints.up("xs")]: {
      color: "white !important",
      fontSize: "12px",
      color: "white",
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "7%",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white !important",
      fontSize: "12px",
      color: "white",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "0px",
    },
  },

  parentButon: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      justifyContent: "center",
      marginTop: "10px",
      fontSize: "18px",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      justifyContent: "center",
      marginTop: "30px",
      fontSize: "20px",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      justifyContent: "flex-start",
      marginTop: "0px",
    },
  },

  imagee: {
    [theme.breakpoints.up("xs")]: {
      width: "12px",
      height: "10px",
    },

    [theme.breakpoints.up("sm")]: {
      width: "12px",
      height: "10px",
    },
    [theme.breakpoints.up("md")]: {
      width: "12px",
      height: "10px",
    },

    [theme.breakpoints.up("lg")]: {
      width: "14px",
      height: "10px",
    },
  },

  parentTextOfUpload: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      fontSize: "16px",
      fontWeight: 500,
    },

    [theme.breakpoints.down("xs")]: {
      color: "white",
      fontSize: "16px",
      fontWeight: 500,
    },
  },

  PhoneEmailGrid: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      flexDirection: "column",
    },

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
  },

  Email: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      justifyContent: "flex-start",
    },

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },

  parentEmail: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "7%",
    },
  },

  parent2upload: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      marginTop: "7%",
      flexDirection: "column",
    },

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      marginTop: "7%",
      flexDirection: "row",
    },
  },

  DashedGrid: {
    [theme.breakpoints.up("xs")]: {
      border: "2.3px dashed grey",
      width: "110%",
      height: "72px",
      marginTop: "7%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    [theme.breakpoints.up("sm")]: {
      border: "2.3px dashed grey",
      width: "97.5%",
      height: "72px",
      marginTop: "7%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  hidefile: {
    [theme.breakpoints.up("xs")]: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      left: "-20px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "200px",
    },

    [theme.breakpoints.down("300")]: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      left: "-20px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "140px",
    },

    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      left: "10px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "160px",
    },

    [theme.breakpoints.between("620", "740")]: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      left: "-10px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "140px",
    },

    [theme.breakpoints.between("600", "620")]: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      left: "-10px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "130px",
    },

    [theme.breakpoints.up("md")]: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      left: "-10px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "140px",
    },

    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      left: "10px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "160px",
    },
  },

  paragraph: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "10px",
    },

    [theme.breakpoints.down("300")]: {
      fontSize: "8px",
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: "8px",
    },

    [theme.breakpoints.between("600", "615")]: {
      fontSize: "7px",
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "10.5px",
    },
  },
}));

function ContactForm({firstName,
lastName,
email,
phone,
message,
loading,
error,
status,
contactUs,
getContactInfo}) {

  function handleReset() {
    // document.querySelectorAll("input").textContent = "";
    // console.log("Clicked");
    getContactInfo('firstName','')
    getContactInfo('lastName','')
    getContactInfo('email','')
    getContactInfo('phone','')
    getContactInfo('message','')
  }
  const classes = useStyles();

  const handleOnChange = (e) =>{
    getContactInfo(e.target.name,e.target.value);
  }

  const submitContactUs = (e) =>{
    e.preventDefault();
    contactUs(firstName,lastName,phone,email,message);
  }

  useEffect(()=>{
    if(status === 'Success'){
      handleReset()
    }
  },[status])

  return (
    <section className="ContactForm">
      <form onSubmit={submitContactUs}>
        <Grid conatiner xs={12} justifyContent="center" alignItems="center">
          <Grid container xs={12} justifyContent="center" alignItems="center">
            <Grid item xs={1} className="f-clearImg">
              <img src={messageImg} />
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              className={classes.ApplicationTxt}
              justifyContent="center"
              alignItems="center"
              className="f-dropSms"
            >
              Drop Us Message From Any Query
            </Grid>
            {/* <Grid
              item
              xs={1}
              style={{ textAlign: "center" }}
              alignItems="center"
              // onClick={handleReset}
            >
              {" "}
              <img src={clearImage} className={classes.imagee} />
            </Grid> */}
            <Grid
              className="f-clearAll"
              item
              xs={3}
              sm={3}
              justifyContent="center"
              alignItems="center"
              // onClick={handleReset}
            >
              <button type="reset" className="button-reset" onClick={handleReset}>
                <img src={clearImage} className={classes.imagee} /> Clear All
              </button>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            style={{ marginTop: "8%" }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CssTextField
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={handleOnChange}
                id="filled-start-adornment"
                sx={{ m: 1, width: "25ch" }}
                variant="standard"
                style={{ width: "99%" }}
                focused
                required
                placeholder="john"
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.inputlabel }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>

            <Grid
              item
              xs={8}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CssTextField
                label="Last Name"
                name="lastName"
                value={lastName}
                onChange={handleOnChange}
                id="filled-start-adornment"
                sx={{ m: 1, width: "25ch" }}
                variant="standard"
                style={{ width: "95%" }}
                focused
                required
                placeholder="Doe"
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.inputlabel }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            style={{ marginTop: "8%" }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CssTextField
                label="Phone"
                name="phone"
                value={phone}
                onChange={handleOnChange}
                id="filled-start-adornment"
                sx={{ m: 1, width: "25ch" }}
                variant="standard"
                style={{ width: "99%" }}
                focused
                required
                placeholder="+1 111 111-11-11"
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.inputlabel }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>

            <Grid
              item
              xs={8}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CssTextField
                label="Email"
                name="email"
                value={email}
                onChange={handleOnChange}
                id="filled-start-adornment"
                sx={{ m: 1, width: "25ch" }}
                variant="standard"
                style={{ width: "95%" }}
                focused
                required
                placeholder="example@email.com"
                InputProps={{ disableUnderline: true }}
                type="email"
                InputLabelProps={{ className: classes.inputlabel }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} style={{ marginTop: "7%" }}>
            <CssTextField
              label="Your message"
              name="message"
              value={message}
              onChange={handleOnChange}
              id="filled-start-adornment"
              sx={{ m: 1, width: "25ch" }}
              variant="standard"
              style={{ width: "100%" }}
              focused
              required
              placeholder="Your message"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ className: classes.inputlabel }}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={12}
            md={4}
            className={classes.parentButon}
            style={{ marginTop: "5%" }}
          >
            <button type="submit" /*onClick={submitContactUs}*/ className="submit-form">
              {/* <Link href="#" color="inherit" underline="always"> */}
                {/* Send */}
              {/* </Link> */}
              { 
                loading ? <CircularProgress style={{color:'#fff'}} size={35} /> : <>Send</>
              }
            </button>
          </Grid>
        </Grid>
      </form>
    </section>
  );
}
const mapStateToProps = (state) =>{
  return{
    firstName: state.ContactR.firstName,
    lastName: state.ContactR.lastName,
    email: state.ContactR.email,
    phone: state.ContactR.phone,
    message: state.ContactR.message,
    loading: state.ContactR.loading,
    error: state.ContactR.error,
    status: state.ContactR.status
  };
}


const mapDispatchToProps = (dispatch) =>{
  return{
      getContactInfo : (name,value) => dispatch(ContactActionCreators.getContactInfo(name,value)), 
      contactUs: (fname,lname,phone,email,message)=> dispatch(ContactActionCreators.contactUs(fname,lname,phone,email,message))
      
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
