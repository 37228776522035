import { Grid } from "@material-ui/core";
import React from "react";
import BusinessPages from "../../../../../src/components/BusinessPages/BusinessPages";
import TextImage from "../../../../components/TextImage/TextImage";
import TextImgImgBigger from "../../../../components/TextImgImgBigger/TextImgImgBigger";
import { TextImageData, InsuranceData } from "./InsuranceData";
import "./Insurance.css";

function Insurance() {
  return (
    <Grid>
      <BusinessPages
        whatwedo={InsuranceData.whatwedo}
        WhiteTitle={InsuranceData.WhiteTitle}
        description={InsuranceData.description}
        BusinessImage={InsuranceData.BusinessImage}
      >
        {/* <Grid item xs={12}>
          <TextImage
            title={TextImageData.title}
            desc={TextImageData.desc}
            Image={TextImageData.Image}
          />
        </Grid> */}

        <Grid item xs={12} className="insurance-imgtext">
          <TextImgImgBigger
            title={TextImageData.title}
            TextContent={TextImageData.desc}
            img={TextImageData.Image}
          />
        </Grid>
      </BusinessPages>
    </Grid>
  );
}

export default Insurance;
