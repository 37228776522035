import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "./logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col one">
            <ul>
              <li>
                {" "}
                <img className="footer-logo" src={logo} alt="" />
              </li>
            </ul>
          </div>
          <div className="footer-col two">
            <ul>
              <li>
                <a>
                  <Link to="/about-us">About</Link>
                </a>
              </li>
              <li>
                <a>
                  <Link to="news-center">News-center</Link>
                </a>
              </li>
              <li>
                <a>
                  <Link to="business">Business</Link>
                </a>
              </li>
              <li>
                <a>
                  <Link to="careers">Careers</Link>
                </a>
              </li>
              <li>
                <a>
                  <Link to="invest">Invest</Link>
                </a>
              </li>
              <li>
                <a>
                  <Link to="contact-us">Contact</Link>
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-col three">
            <div className="social-links">
              <a
                href="https://www.facebook.com/AnchorVenturesHolding"
                target="_blank"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/anchorventuresholding/?hl=en"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/anchor-ventures-holding/"
                target="_blank"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                  href="https://mobile.twitter.com/AnchorVHoldings"
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// <section classNameName="footer">
//   <div classNameName="footer-container">
//     <img classNameName="footer-logo" src={logo} alt="" />
//     <div classNameName="links-container">
//       <span>
//         {" "}
//         <a src="#">About</a>{" "}
//       </span>
//       <span>
//         {" "}
//         <a src="#">Business</a>{" "}
//       </span>
//       <span>
//         {" "}
//         <a src="#">Invest</a>{" "}
//       </span>
//       <span>
//         {" "}
//         <a src="#">News</a>{" "}
//       </span>
//       <span>
//         {" "}
//         <a src="#">Careers</a>{" "}
//       </span>
//       <span>
//         {" "}
//         <a src="#">Contact</a>{" "}
//       </span>
//     </div>
//     <div classNameName="icons-container">
//       <img src={fcb} alt="" />
//       <img src={insta} alt="" />
//       <img src={linkedin} alt="" />
//     </div>
//   </div>
//   {/* <div classNameName="copy-right">
//     © 2021 Anchor Venture Holdings All Rights Reserved.
//   </div> */}
// </section>
