import img from './TelecommunicationImages/TelecomImgg.png'
import img2 from './TelecommunicationImages/TextImgTelecom.png'


export const TelecommunicationsData = {

    whatwedo: "WHAT WE DO",
    WhiteTitle: "TELECOMMUNICATIONS",
    description: "AVH is a leading provider of telecommunications in Australia and throughout the MENA Region. We bring together best-in-class business processes with emerging technologies for seamless customer experiences and market leadership. Telecommunications services include consultancy, integration, operation, installation and maintenance.",
    BusinessImage: img,
};

export const TextImgsTelecommunications = {

    title: "Advanced Digital Telecommunications Platforms",
    TextContents: "NUTEL is a digital marketplace that connects telecommunications firms, service providers and end users in one place for the full stack of exchange data, voice, mobile, colocation and customer service solutions",
    Image: img2,
};
