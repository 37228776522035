import { Grid , makeStyles} from '@material-ui/core'
import React, {useEffect} from 'react'
import BusinessPages from '../../../../components/BusinessPages/BusinessPages';
import TextImgImgBigger from '../../../../components/TextImgImgBigger/TextImgImgBigger';
import { TelecommunicationsData, TextImgsTelecommunications } from './TelecommunicationData';
import './Telecome.css'


const useStyles=makeStyles((theme)=>({


  imgText: {
      [theme.breakpoints.up("xs")]:{
        marginTop:"100px",
        marginBottom:"30px"
      },

      [theme.breakpoints.down("380")]:{
        marginTop:"110px"
      },
      
      [theme.breakpoints.down("370")]:{
        marginTop:"170px"
      },
      
      [theme.breakpoints.up("sm")]:{
        marginTop:"30px",
        marginBottom:"70px"
      },

      [theme.breakpoints.between("600","650")]:{
        marginTop:"100px",
        marginBottom:"70px"
      },

      [theme.breakpoints.up("md")]:{
        marginTop:"-50px",
        marginBottom:"40px"

      },
      
  },

}));

function Telecommunications() {

  const classes= useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <Grid>
      <BusinessPages
        whatwedo={TelecommunicationsData.whatwedo}
        WhiteTitle={TelecommunicationsData.WhiteTitle}
        AquaTitle={TelecommunicationsData.AquaTitle}
        description={TelecommunicationsData.description}
        BusinessImage={TelecommunicationsData.BusinessImage}
      >


      <Grid item xs={12} className={classes.imgText} id="imagetextTelecome" >
        <TextImgImgBigger Animated 
          title={TextImgsTelecommunications .title}
          TextContent={TextImgsTelecommunications .TextContents}
          img={TextImgsTelecommunications .Image}
        />
      </Grid>
      </BusinessPages>
    </Grid>

  )
}

export default Telecommunications
