import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useMediaQuery } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import "./LargeImageTextBelow.css";

function LargeImageTextBelow({
  Animated = false,
  titleOne,
  titleTwo,
  desc1,
  Image,
  link
}) {
  useEffect(() => {
    AOS.init();
  }, []);
  const reverseContent = useMediaQuery("(max-width: 600px)");

  return (
    <section className="largeimageTextbelow">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction={reverseContent ? "column-reverse" : null}
      >
        <Grid
          item
          xs={11}
          data-aos={Animated ? "slide-right" : ""}
          data-aos-duration={Animated ? "3000" : ""}
        >
          <div className="img-cont">
            <img src={Image} alt="img" width="100%" />
          </div>
        </Grid>

        <Grid
          item
          xs={11}
          data-aos={Animated ? "zoom-in" : ""}
          data-aos-duration={Animated ? "2000" : ""}
        >
          <div className="text-cont">
            <p className="title1">{titleOne}</p>
            <p className="title2">{titleTwo}</p>
            <p className="desc1">{desc1}</p>
            <div className="btn-handler">
              <a href={link}>
                <button className="learn-more">Read more</button>
              </a>

              {/* <p className="date">12-04-2021</p> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  );
}
export default LargeImageTextBelow;
