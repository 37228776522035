import * as actionTypes from '../actions/actions';

const initialState = {
    news: '',
    news1: '',
    news2: ''  
}

const AvhNewsR =  (state = initialState, action) => {

    switch (action.type) { 
        case actionTypes.AvhNews.GET_AVH_NEWS_DETAILS :
            return { ...state, news : action.newsA  }

        case actionTypes.AvhNews.GET_MORE_NEWS :
            return { ...state,news1: action.news1,news2: action.news2}  
                    
        default:
            return state
    }
}

export default AvhNewsR;