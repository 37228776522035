import { Grid, Link, makeStyles, TextField, styled ,CircularProgress} from "@material-ui/core";
import React, { useEffect } from "react";
import message from "./Images/message.svg";
import clearImage from "./Images/clearicon.jpg";
import UploadImage from "./Images/UploadIcon.png";
import { connect } from 'react-redux';
import * as SendCvActionCreators from '../../store/actionsCreators/SendCvAction';

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
    opacity: "50%",
  },
});

const useStyles = makeStyles((theme) => ({
  BoxParent: {
    [theme.breakpoints.up("md")]: {
      width: "1200px",
      height: "1006px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      filter: " blur(8px)",
    },
  },

  parentofall: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "-150px",
      marginBottom: "30px",
    },

    [theme.breakpoints.up("md")]: {
      marginTop: "-350px",
      marginBottom: "30px",
    },

    [theme.breakpoints.between("960", "1200")]: {
      marginTop: "-350px",
    },

    [theme.breakpoints.up("lg")]: {
      marginTop: "-500px",
      marginBottom: "50px",
    },
  },

  ApplicationTxt: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      fontSize: "12px",
      fontWeight: 500,
      display: "flex",
      justifyContent: "center",
    },

    [theme.breakpoints.down("347")]: {
      color: "white",
      fontSize: "10px",
      fontWeight: 500,
      display: "flex",
      justifyContent: "center",
    },

    [theme.breakpoints.down("294")]: {
      color: "white",
      fontSize: "8px",
      fontWeight: 400,
      display: "flex",
      justifyContent: "flex-start",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      fontSize: "14px",
      fontWeight: 500,
      display: "flex",
      justifyContent: "center",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      fontSize: "22px",
      fontWeight: 500,
      display: "flex",
      justifyContent: "center",
    },
  },

  clearTxt: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      fontSize: "12px",
      opacity: "50%",
      fontWeight: 400,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },

    [theme.breakpoints.down("292")]: {
      color: "white",
      fontSize: "10px",
      opacity: "50%",
      fontWeight: 400,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      fontSize: "14px",
      opacity: "50%",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
    },
  },

  input: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      wordBreak: "break-all",
      borderBottom: "1px solid #808080",
      fontSize: "12px",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      wordBreak: "break-all",
      borderBottom: "1px solid #808080",
      fontSize: "14px",
      height: "50px",
    },

    [theme.breakpoints.down("712")]: {
      color: "white",
      wordBreak: "break-all",
      borderBottom: "1px solid #808080",
      fontSize: "12px",
    },

    [theme.breakpoints.down("628")]: {
      color: "white",
      wordBreak: "break-all",
      borderBottom: "1px solid #808080",
      fontSize: "11px",
    },
  },

  inputlabel: {
    [theme.breakpoints.up("xs")]: {
      color: "white !important",
      fontSize: "14px",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white !important",
      fontSize: "16px",
    },

    [theme.breakpoints.down("712")]: {
      color: "white !important",
      fontSize: "14px",
    },

    [theme.breakpoints.down("628")]: {
      color: "white !important",
      fontSize: "13px",
    },

    [theme.breakpoints.up("md")]: {
      color: "white !important",
      fontSize: "22px",
      fontWeight: 500,
    },
  },

  parentUploadFolder: {
    [theme.breakpoints.up("xs")]: {
      color: "white !important",
      fontSize: "12px",
      color: "white",
      display: "flex",
      justifyContent: "flex-start",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white !important",
      fontSize: "12px",
      color: "white",
      display: "flex",
      justifyContent: "flex-start",
    },
  },

  parentUploadFolder2: {
    [theme.breakpoints.up("xs")]: {
      color: "white !important",
      fontSize: "12px",
      color: "white",
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "7%",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white !important",
      fontSize: "12px",
      color: "white",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "0px",
    },
  },

  parentButon: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      justifyContent: "center",
      marginTop: "10px",
      fontSize: "18px",
    },

    [theme.breakpoints.up("sm")]: {
      color: "white",
      justifyContent: "center",
      marginTop: "30px",
      fontSize: "20px",
    },

    [theme.breakpoints.up("md")]: {
      color: "white",
      justifyContent: "flex-start",
      marginTop: "0px",
    },
  },

  imagee: {
    [theme.breakpoints.up("xs")]: {
      width: "12px",
      height: "10px",
    },

    [theme.breakpoints.up("sm")]: {
      width: "12px",
      height: "10px",
    },
    [theme.breakpoints.up("md")]: {
      width: "12px",
      height: "10px",
    },

    [theme.breakpoints.up("lg")]: {
      width: "14px",
      height: "10px",
    },
  },

  parentTextOfUpload: {
    [theme.breakpoints.up("xs")]: {
      color: "white",
      fontSize: "16px",
      fontWeight: 500,
    },

    [theme.breakpoints.down("xs")]: {
      color: "white",
      fontSize: "16px",
      fontWeight: 500,
    },
  },

  PhoneEmailGrid: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      flexDirection: "column",
    },

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
  },

  Email: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      justifyContent: "flex-start",
    },

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },

  parentEmail: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "7%",
    },
  },

  parent2upload: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      marginTop: "7%",
      flexDirection: "column",
    },

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      marginTop: "7%",
      flexDirection: "row",
    },
  },

  DashedGrid: {
    [theme.breakpoints.up("xs")]: {
      border: "2.3px dashed grey",
      width: "110%",
      height: "72px",
      marginTop: "7%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    [theme.breakpoints.up("sm")]: {
      border: "2.3px dashed grey",
      width: "97.5%",
      height: "72px",
      marginTop: "7%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  hidefile: {
    [theme.breakpoints.up("xs")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-20px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "200px",
    },

    [theme.breakpoints.down("300")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-20px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "140px",
    },

    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "10px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "160px",
    },

    [theme.breakpoints.between("620", "740")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-10px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "140px",
    },

    [theme.breakpoints.between("600", "620")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-10px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "130px",
    },

    [theme.breakpoints.up("md")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "-10px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "140px",
    },

    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      // Zindex: 1000,
      opacity: 0,
      cursor: "pointer",
      left: "10px",
      right: 0,
      top: "-20px",
      height: "60px",
      width: "160px",
    },
  },

  paragraph: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "10px",
      // backgroundColo:"green"
    },

    [theme.breakpoints.down("300")]: {
      fontSize: "8px",
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: "8px",
      // backgroundColo:"green"
    },

    [theme.breakpoints.between("600", "615")]: {
      fontSize: "7px",
      // backgroundColo:"green"
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "10.5px",
      // backgroundColo:"green"
    },
  },
}));

function SendCvForm({firstName,
lastName,
headLine,
email,
phone,
address,
coverLetter,
resume,
loading,
error,
status,
getSendInfo,
SendCv}) {
  const classes = useStyles();

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [stateChooseCover, setStateChooseCover] = React.useState(true);
  const [stateChooseResume, setStateChooseResume] = React.useState(true);

  var Covernames = "";
  const Coverpressed = (e) => {
    var filess = document.getElementById("cover_upload").files;
    getSendInfo("coverLetter", filess[0]);
    if (filess.length == 0) {
      alert("Please first choose or drop any file(s)...");
      return;
    }

    for (var i = 0; i < filess.length; i++) {
      Covernames += filess[i].name + "\n";
    }
    setStateChooseCover(false);
    // alert("Selected file(s) :\n____________________\n"+filenames);
    document.getElementById("Cover-name").innerHTML = Covernames;
  };

  var Resumenames = "";
  const Resumepressed = (e) => {
    var filesss = document.getElementById("resume_upload").files;
    getSendInfo("resume", filesss[0]);
    if (filesss.length == 0) {
      alert("Please first choose or drop any file(s)...");
      return;
    }

    for (var g = 0; g < filesss.length; g++) {
      Resumenames += filesss[g].name + "\n";
    }
    setStateChooseResume(false);
    // alert("Selected file(s) :\n____________________\n"+filenames);
    document.getElementById("Resume-name").innerHTML = Resumenames;
  };

  const handleOnChange = (e) =>{
    getSendInfo(e.target.name,e.target.value);
  } 

 const submitSendCv = (e) =>{
   e.preventDefault();
   SendCv(firstName,lastName,headLine,phone,email,address,coverLetter,resume);
 } 

 const resetAll = () =>{
  getSendInfo("firstName",'');
  getSendInfo("lastName",'');
  getSendInfo("headLine",'');
  getSendInfo("email",'');
  getSendInfo("phone",'');
  getSendInfo("address",'');
  getSendInfo("coverLetter",'');
  getSendInfo("resume",'');
  getSendInfo("loading",false);
  getSendInfo("error",'');
  getSendInfo("status",'');

 }

 useEffect(()=>{
   if(status === 'Success'){
    resetAll();
   }
 },[status])

  return (
    <form onSubmit={submitSendCv}>
      <Grid item xs={11}>
        <Grid container item xs={12}>
          <Grid
            container
            item
            xs={8}
            lg={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid
              item
              xs={4}
              sm={1}
              md={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              {" "}
              <img src={message} />{" "}
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={6}
              lg={7}
              className={classes.ApplicationTxt}
            >
              {" "}
              Send CV{" "}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={4}
            lg={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              item
              xs={10}
              sm={11}
              md={11}
              spacing={1}
              className={classes.clearTxt}
            >
              {" "}
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img src={clearImage} className={classes.imagee} />
              </Grid>
              <button
                type="reset"
                className="button-reset-b"
                // style={
                //   ({ backgroundColor: "transparent" },
                //   { color: "white" },
                //   { border: "none" })
                // }
                onClick={resetAll}
              >
                clear all
              </button>
              {/* clear all */}
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ marginTop: "8%" }}>
          <Grid container item xs={4}>
            <Grid
              item
              xs={12}
              style={{ border: "1px solid white", opacity: "50%" }}
            ></Grid>
          </Grid>
          <Grid container item xs={8}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CssTextField
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={handleOnChange}
                id="filled-start-adornment"
                sx={{ m: 1, width: "25ch" }}
                variant="standard"
                style={{ width: "90%" }}
                focused
                required
                placeholder="john"
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.inputlabel }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10%",
              }}
            >
              <CssTextField
                label="Last Name"
                id="filled-start-adornment"
                name="lastName"
                value={lastName}
                onChange={handleOnChange}
                sx={{ m: 1, width: "25ch" }}
                variant="standard"
                style={{ width: "90%" }}
                focused
                required
                placeholder="john"
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.inputlabel }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ marginTop: "7%" }}>
          <CssTextField
            label="Headline"
            id="filled-start-adornment"
            sx={{ m: 1, width: "25ch" }}
            name="headLine"
            value={headLine}
            onChange={handleOnChange}
            variant="standard"
            style={{ width: "100%" }}
            focused
            required
            placeholder="Headline"
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ className: classes.inputlabel }}
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>

        <Grid
          container
          item
          xs={12}
          style={{ marginTop: "7%" }}
          clssName={classes.PhoneEmailGrid}
        >
          <Grid container item xs={12} sm={4}>
            <CssTextField
              label="Phone"
              id="filled-start-adornment"
              sx={{ m: 1, width: "25ch" }}
              name="phone"
              value={phone}
              onChange={handleOnChange}
              variant="standard"
              style={{ width: "90%" }}
              focused
              required
              placeholder="+1 111 111-11-11"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ className: classes.inputlabel }}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid container item xs={12} sm={8} className={classes.parentEmail}>
            <Grid item xs={12} className={classes.Email}>
              <CssTextField
                label="Email"
                id="filled-start-adornment"
                sx={{ m: 1, width: "25ch" }}
                name="email"
                value={email}
                onChange={handleOnChange}
                variant="standard"
                style={{ width: "90%" }}
                focused
                required
                placeholder="example@email.com"
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.inputlabel }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ marginTop: "7%" }}>
          <CssTextField
            label="Address"
            id="filled-start-adornment"
            sx={{ m: 1, width: "25ch" }}
            name="address"
            value={address}
            onChange={handleOnChange}
            variant="standard"
            style={{ width: "100%" }}
            focused
            required
            placeholder="Street Example, 23-1"
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ className: classes.inputlabel }}
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>

        <Grid container item xs={12} className={classes.parent2upload}>
          <Grid
            container
            item
            xs={12}
            sm={6}
            className={classes.parentUploadFolder}
          >
            <Grid item xs={11}>
              <Grid item xs={12} className={classes.parentTextOfUpload}>
                Cover Letter
              </Grid>
              <Grid item xs={12} className={classes.DashedGrid}>
                <Grid
                  item
                  xs={8}
                  className="upload-container"
                  style={{
                    display: stateChooseCover ? "flex" : "none",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="file"
                    id="cover_upload"
                    // multiple
                    required
                    onChange={Coverpressed}
                    className={classes.hidefile}
                  />
                  <Grid item xs={1}>
                    <img src={UploadImage} width="15px" height="15px" />
                  </Grid>
                  <Grid item xs={11}>
                    <p
                      className={classes.paragraph}
                      style={{ marginLeft: "10px" }}
                    >
                      upload your cover letter or drag and drop here{" "}
                    </p>
                  </Grid>
                </Grid>

                <div
                  style={{ color: "white", opacity: "100%" }}
                  id="Cover-name"
                >
                  {Covernames}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={6}
            className={classes.parentUploadFolder2}
          >
            <Grid item xs={11}>
              <Grid item xs={12} className={classes.parentTextOfUpload}>
                Resume
              </Grid>
              <Grid item xs={12} className={classes.DashedGrid}>
                <Grid
                  item
                  xs={8}
                  className="upload-container"
                  style={{
                    display: stateChooseResume ? "flex" : "none",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="file"
                    id="resume_upload"
                    // multiple
                    required
                    onChange={Resumepressed}
                    className={classes.hidefile}
                  />
                  <Grid item xs={1} style={{ opacity: "100%" }}>
                    <img src={UploadImage} width="15px" height="15px" />
                  </Grid>
                  <Grid item xs={11} style={{ opacity: "100%" }}>
                    <p
                      className={classes.paragraph}
                      style={{ marginLeft: "10px" }}
                    >
                      upload your resume or drag and drop here{" "}
                    </p>
                  </Grid>
                </Grid>
                <div
                  style={{ color: "white", opacity: "100%" }}
                  id="Resume-name"
                >
                  {Resumenames}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={4}
          className={classes.parentButon}
          style={{ marginTop: "5%" }}
        >
          <button  type="submit" className="submit-form">
              { 
                loading ? <CircularProgress style={{color:'#fff'}} size={35} /> : <>Submit</>
              }
            </button>
          {/* <Link href="#" color="inherit" underline="always">
            submit
          </Link> */}
          
        </Grid>
      </Grid>
      </form>
    
  );
}

const mapStateToProps = (state) =>{
  return{
    firstName: state.SendCvR.firstName,
    lastName: state.SendCvR.lastName,
    headLine: state.SendCvR.headLine,
    email: state.SendCvR.email,
    phone: state.SendCvR.phone,
    address: state.SendCvR.address,
    coverLetter: state.SendCvR.coverLetter,
    resume: state.SendCvR.resume,
    loading: state.SendCvR.loading,
    error: state.SendCvR.error,
    status: state.SendCvR.status
  };
}


const mapDispatchToProps = (dispatch) =>{
  return{
      getSendInfo : (name,value) => dispatch(SendCvActionCreators.getSendInfo(name,value)), 
      SendCv: (fname,lname,headLine,phone,email,address,coverLetter,resume)=> dispatch(SendCvActionCreators.SendCv(fname,lname,headLine,phone,email,address,coverLetter,resume))
      
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendCvForm)

