import img from "./images/AviationHome.png";
import img2 from "./images/AviationIm2.png";
import img1 from "./images/img1.png";

export const AviationData = {
  whatwedo: "WHAT WE DO",
  WhiteTitle: "AVIATION",
  BusinessImage: img,
};
export const ImageTextData = {
  title: "Full Range of Aviation Services",
  desc: "AVH delivers premier aviation services around the world, including private jet and charter flights, flight planning and permission services, fueling, ground handling, catering, cabin cleaning and maintenance handling. Clients benefit from procuring timely and efficient aviation services from one partner, with complete confidence in AVH’s commitment to safety, reliability and excellence.",
  Image: img1,
};
export const TextImageData = {
  title: "Advanced Digital Platform for Aviation",
  desc: "NUAVIATION [suggested name] is an advanced digital marketplace for aviation services. It seamlessly connects all buyers and sellers on one platform, transforming customer experience and service delivery to new levels of excellence.",
  Image: img2,
};
