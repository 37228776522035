
import React, { useEffect } from "react";
import { Grid , makeStyles} from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import TextOfLeftImage from "./TextOfLeftImage";
import img from './im.png'


const useStyles=makeStyles((theme)=>({

  parent:{
     

      [theme.breakpoints.up("xs")]:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column-reverse"
      },

      [theme.breakpoints.up("md")]:{
        display:"flex",
        alignItems:"center",
        flexDirection:"row"

      },
     
  },

  imageParentt:{
    [theme.breakpoints.up("xs")]:{
      marginTop:"10px"
    },

    [theme.breakpoints.up("sm")]:{
      marginTop:"10px"
    },

    [theme.breakpoints.up("md")]:{
      marginTop:"0px"
    },
  }
  
}));



const ImageTextWithCheckMark = ({ Animated = false }) => {

  const classes= useStyles();

  useEffect(() => {
    AOS.init(); 
  }, []);

  const smm= "890";
  return (
        <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        
        className={classes.parent}
        >

                <Grid
                item
                xs={10}
                sm={10}
                md={6}
                //   sm={7}
                // style={{height:"265px"}}
                
                data-aos={Animated ? "zoom-in" : ""}
                data-aos-duration={Animated ? "3000" : ""}

                className={classes.imageParentt}
                >
                    <img src={img} alt="" width="100%" height="100%" />
                </Grid>
 

                <Grid
                container
                xs={10}
                sm={10}
                md={6}
                //   sm={5}
                data-aos={Animated ? "slide-left" : ""}
                data-aos-duration={Animated ? "2000" : ""}
                style={{display:"flex",alignItems:"center"}}
                
                // className={classes.LeftText}
                style={{justifyContent:"center"}}
                >
                  <Grid item xs={10} sm={11}>
                     <TextOfLeftImage/>
                   </Grid>
                </Grid>
        </Grid>
  );
};

export default ImageTextWithCheckMark;
