
import img from './Images/careeresImage.png'

export const SeniorAccountData = {

    whatwedo: "Careers",
    WhiteTitle: "SENIOR",
    AquaTitle: "ACCOUNT EXECUTIVE",
    description: "The senior account executive's responsibilities include building and maintaining a sales pipeline with a high conversation rate, attending sales meetings to provide progress updates, and researching industry trends. identify new sales opportunities through networking initiatives and customer referrals, adept at analysing sales performance metrics and negotiating contracts, Ultimately, a senior account executive should demonstrate excellent communication, management, and customer service skills at all times.",
    BusinessImage: img,

};

export const SeniorArtData = {

    whatwedo: "Careers",
    WhiteTitle: "SENIOR",
    AquaTitle: "ART DIRECTORY",
    description: "Senior Art Director is responsible for translating marketing objectives into creative strategies, design, and plans. As well manages graphic design, website development, collateral development, copywriting, advertising, and promotions. The Ideal candidate will be self-motivated, resourceful, and have impeccable design and leadership skills.",
    BusinessImage: img,

};

export const SeniorWriterData = {

    whatwedo: "Careers",
    WhiteTitle: "SENIOR",
    AquaTitle: "CONTENT WRITER",
    description: "The senior content writer is effectively demonstrated for exceptional writing abilities and great attention to details. Ultimately, a competent senior copywriter will be highly experienced in creating engaging content for social media and in charge of influencing consumers by delivering a clear message, as well as using their initiative to seek out new trends and incorporate them into campaigns.",
    BusinessImage: img,

};


