import { AuthorInfoData } from "./AuthorInfoData";
import "./AuthorInfo.css";
import { Grid } from "@material-ui/core";
function AuthorInfo({authorName,authorImage}) {
  return (
    <section className="authorinfo">
      <Grid>
        <div className="author">
          <Grid item /*sm={6}*/ md={4} sm={4} xs={12}>
            <div className="author-info">
              {/* <img
                src={authorImage}//{AuthorInfoData.authorimg}
                alt=""
                className="author-img"
              /> */}
              <div>
                <p className="author-title">{AuthorInfoData.authortitle}</p>
                <p className="author-name">{authorName} {/*{AuthorInfoData.authorname}*/}</p>
              </div>
            </div>
          </Grid>
          {/* <Grid item lg={6} md={4} sm={3} xs={12}>
            <p className="sharearticle">Share this article</p>
          </Grid>
          <Grid item lg={4} md={4} sm={5} xs={12}>
            <div className="footer-col">
              <div className="social-links footer-col">
                <a
                  href="https://www.facebook.com/AnchorVenturesHolding"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.instagram.com/anchorventuresholding/?hl=en"
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/anchorventuresholding/?hl=en"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/anchor-ventures-holding/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </Grid> */}
        </div>
      </Grid>

      {/* <p className="desc">{AuthorInfoData.authorDesc}</p> */}
    </section>
  );
}
export default AuthorInfo;
