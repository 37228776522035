import { Grid , makeStyles} from '@material-ui/core'
import React, {useEffect} from 'react'
import BusinessPages from '../../../../components/BusinessPages/BusinessPages';
import ImgTextText from '../../../../components/ImgTextText/ImgTextText';
import { GovernmentData, ImgTextData } from './GovernmentData';
import './Government.css'

const useStyles=makeStyles((theme)=>({


  imgText: {
      [theme.breakpoints.down("290")]:{
        marginTop:"55px"
      },

      [theme.breakpoints.up("sm")]:{
        marginTop:"25px",
        marginBottom:"45px",

      },

      [theme.breakpoints.up("md")]:{
        marginTop:"-60px",
        marginBottom:"390px"
      },

      
      [theme.breakpoints.up("md")]:{
        marginTop:"-100px",
        marginBottom:"120px"
      },

      
  },

}));

function Government() {

  const classes= useStyles();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 


  return (
    <Grid>
        <BusinessPages
            whatwedo={GovernmentData.whatwedo}
            WhiteTitle={GovernmentData.WhiteTitle}
            AquaTitle={GovernmentData.AquaTitle}
            description={GovernmentData.description}
            BusinessImage={GovernmentData.BusinessImage}
        >

            <Grid item xs={12} className={classes.imgText} id="imagetextt">
                <ImgTextText Animated
                title={ImgTextData.title}
                TextContent={ImgTextData.TextContent}
                img={ImgTextData.ImageText} />
            </Grid>

         </BusinessPages>
    </Grid>
  )
}

export default Government
