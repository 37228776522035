import img from './TravelImages/TravelHome.png'
import img2 from './TravelImages/TextImageTravel.png'


export const TravelData = {

    whatwedo: "WHAT WE DO",
    WhiteTitle: "TRAVEL",
    description: "AVH provides one-stop travel solutions for individuals and companies around the world. Our network of specialized travel brands manages every aspect of leisure and corporate travel. AVH combines travel industry know-how with technology expertise to create world-class digital travel solutions.",
    BusinessImage: img,
};

export const TextImgsTravel = {

    title: "Advanced Digital Travel Platform",
    TextContents: "NUTRAVEL is an advanced digital travel marketplace. It renews efficiency and profitability for an industry hit hard by the COVID-19 pandemic. NUTRAVEL connects Destination Management Companies, Outbound Tour Operators, MICE organizations, business travelers, leisure travelers and online travel agencies to source, compare and buy all travel services on one seamless platform",
    Image: img2,
};
