import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { ImageLargeText } from "./ImageLargeTextData";
import AOS from "aos";
import "aos/dist/aos.css";

import "./ImageLArgeText.css";
import ReactHtmlParser from 'react-html-parser'

const ImageLargeTextt = ({ Animated = false ,newsBody, largeImage}) => {
  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <section className="ImageLargeText">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        data-aos={Animated ? "zoom-in" : ""}
        data-aos-duration={Animated ? "3000" : ""}
      >
        <Grid item xs={11}>
          <img src={largeImage} /*{ImageLargeText.image}*/ alt="" width="100%" />
        </Grid>

        <Grid item xs={10}>
          <div>
            <p className="desc">{ReactHtmlParser(newsBody)}</p>
            {/* <p className="desc">{ImageLargeText.desc2}</p>
            <p className="desc">{ImageLargeText.desc3}</p>
            <p className="desc">{ImageLargeText.desc
            4}</p>*/}
            {/* <p className="white"> 
              {ImageLargeText.white1}
              <span className="green">{ImageLargeText.green1}</span>
            </p>
            <p className="desc">{ImageLargeText.desc5}</p>
            <p className="desc">
              {ImageLargeText.desc6}
              <a
                href="https://www.hazelviewventures.com/"
                target="_blank"
                style={{ color: "white" }}
              >
                {ImageLargeText.desc66}
              </a>
            </p>
            <p className="white">
              {ImageLargeText.white2}
              <span className="green">{ImageLargeText.green2}</span>
            </p>
            <p className="desc">
              {ImageLargeText.desc7}
              <a
                href="https://www.hazelviewventures.com/"
                target="_blank"
                style={{ color: "white" }}
              >
                {ImageLargeText.desc77}
              </a>
            </p>
            <p className="white">
              {ImageLargeText.white3}
              <span className="green">{ImageLargeText.green3}</span>
            </p>
            <p className="desc">
              {ImageLargeText.desc8}
              <a
                href="https://www.rhenti.com/"
                target="_blank"
                style={{ color: "white" }}
              >
                {ImageLargeText.desc88}
              </a>
              <span>{ImageLargeText.desc888}</span>
            </p> */}
            <p className="more-news">MORE NEWS</p>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default ImageLargeTextt;
