import im1 from "./Images/im1.png";
import im2 from "./Images/im2.png";

export const TextImgImgData = {
  titleOne: "A Startegic Mix Of Industry and Technology",
  titleTwo: " To Answer Any Business Challenge",
  desc: "Anchor Ventures Holding (AVH) manages a diversified portfolio of global companies and prepares them to emerge as market leaders across industries.",
  image1: im1,
  image2: im2,
};
