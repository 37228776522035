import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import img from './checkMark.svg' 



const useStyles=makeStyles((theme)=>({


    title:{

        [theme.breakpoints.up("xs")]:{
            fontSize:"13px",
            fontWeight:500
        },

        [theme.breakpoints.up("sm")]:{
            fontSize:"18px",
            fontWeight:500
        },
    },

    MarginBetweenTtileP:{

        [theme.breakpoints.up("xs")]:{
            marginTop:"15px"
        },

        [theme.breakpoints.up("sm")]:{
            marginTop:"20px"
        },

        [theme.breakpoints.up("lg")]:{
           marginTop:"15px"
        },
    },

   

    parentText: {
  
        [theme.breakpoints.up("xs")]:{
            fontSize:"10px",
            opacity:"50%",
            fontWeight:400,
            display:"flex",
         
        },
    

        [theme.breakpoints.up("sm")]:{
            fontSize:"12px",
            opacity:"50%",
            fontWeight:400
        },
    
        [theme.breakpoints.up("md")]:{
            fontSize:"12px",
            opacity:"50%",
            fontWeight:400
        },
    },

    recenterCheckMark: {
        [theme.breakpoints.up("lg")]:{
            display:"flex",
            // alignItems:"center"
        },
    },

    secondeParagraph: {

        [theme.breakpoints.up("xs")]:{
            marginTop:"30px",

        },


        [theme.breakpoints.up("sm")]:{
            marginTop:"0px",

        },


        [theme.breakpoints.up("md")]:{
            marginTop:"20px",
        },

        [theme.breakpoints.up("lg")]:{
            marginTop:"50px"
        },
    },

    prentResponsive: {
        [theme.breakpoints.up("xs")]:{
            display:"flex",
            flexDirection:"row",
            marginLeft:"0px"

        },

        [theme.breakpoints.up("sm")]:{
            display:"flex",
            flexDirection:"row",
            marginLeft:"0px"

        },

        [theme.breakpoints.up("md")]:{
            display:"flex",
            flexDirection:"row",
            marginLeft:"20px"


        },

        [theme.breakpoints.up("lg")]:{
            display:"flex",
            flexDirection:"row",
            marginLeft:"30px"


        },
    },

    
  
  
  }));


function SeniorGD() {

const classes = useStyles();

    return (
        <Grid container item xs={12} style={{color:"white"}} className={classes.prentResponsive}>
            
            <Grid  item xs={12} sm={6} md={11} className={classes.recentertextwith}>
                <Grid item xs={10}  md={12} className={classes.title}>Resbonsibilites</Grid>
                <Grid container item xs={12} sm={10} md={12} spacing={1} className={classes.MarginBetweenTtileP}>
                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1} > <img src={img} width="18px" height="18px" /> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Overseeing a team of graphic designers to ensure the accurate visual delivery of ideas  </Grid>
                    </Grid>
                    
                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Fostering a unique visual identity for the company </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Developing and implementing ideas for social media and website content </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Improving the visual identity of the company </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Ensuring a swift communication between all elements of the communications department team </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Ensuring the content is delivered on time </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Double-checking the content before it gets posted </Grid>
                    </Grid>

                    {/* <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Collecting customer feedback to determine product and service shortfalls. </Grid>
                    </Grid> */}
                </Grid>
            </Grid>



            <Grid  item xs={12} sm={6} md={12} className={classes.secondeParagraph}>
                <Grid item xs={10} md={12} className={classes.title}>Requirements</Grid>
                <Grid container item xs={12} sm={10} md={12} spacing={1} className={classes.MarginBetweenTtileP}>
                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px" /> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Bachelor's degree in graphic design, a master's degree is a plus  </Grid>
                    </Grid>
                    
                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Experience working as a senior graphic designer </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Proficiency in all of the Adobe package including: Photoshop, Illustrator, Premier and After Effects </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Excellent communication skills </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Excellent management skills </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Good problem-solving skills </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Accuracy, good time management  and attention to details </Grid>
                    </Grid>

                    {/* <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Exceptional customer service skills. </Grid>
                    </Grid> */}
                </Grid>
            </Grid>



        </Grid>
    )
}

export default SeniorGD
