import { Grid } from "@material-ui/core";
import ContactUsHome from "../../components/ContactUsHome/ContactUsHome";
import ContactUsInfo from "../../components/ContactUsInfo/ContactUsInfo";
import ContactForm from "../../components/ContactForm/ContactForm";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import "./Contact.css";

function Contact() {
  return (
    <section>
      <Navbar />
      <ContactUsHome />
      <Grid
        className="contact-grid"
        container
        justifyContent="center"
        spacing={4}
        alignItems="center"
        // style={{ marginTop: "-20%" }}
      >
        <Grid item xs={11} sm={5}>
          <ContactUsInfo />
        </Grid>
        <Grid item xs={11} sm={5}>
          <ContactForm />
        </Grid>
      </Grid>
      <Footer />
    </section>
  );
}
export default Contact;
