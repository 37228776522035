import img from "./images/im.png";
import img2 from "./images/img2.png";

export const StartupData = {
  whatwedo: "WHAT WE DO",
  WhiteTitle: "START UP",
  description:
    "AVH is passionate about scaling new businesses ideas to become thriving value-creation machines. Many businesses, however, struggle with the ‘innovator’s dilemma:’ finding the time and resources to innovate while maintaining current business operations. This is where AVH innovation management comes in.",
  BusinessImage: img,
};

export const TextImageData = {
  title: "Advanced Digital Platform for Innovation Management",
  desc: "NUVATION is a digital platform for managing innovation in a radically more efficient and collaborative way. It connects creative university students, entrepreneurs and enterprise innovators with capital, resources, and professional mentoring. NUVATION’s guided, automated processes accelerate the development of ideas into scalable, market-leading businesses. Investors have unmatched access to a hotbed of innovation, allowing valuable products and services to find their way to end users.",
  Image: img2,
};
