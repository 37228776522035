import "./NewsCenterHome.css";

import { NewsCenterHomeData } from "./NewsCenterHomeData";
function NewsCenterHome({whiteTitle,greenTitle,coverImage}) {
  return (
    <section className="NewsCenterHome">
      <img src={coverImage}></img>
      <div className="news-overlay">
        <p className="pretitle">{NewsCenterHomeData.whatwedo}</p>
        <p className="title1">{whiteTitle} </p>
        {/* <p className="title1">{NewsCenterHomeData.WhiteTitle2} </p> */}
        <p className="title2">{greenTitle} </p>
        {/* <p className="date">12-04-2021</p> */}
      </div>
    </section>
  );
}
export default NewsCenterHome;
