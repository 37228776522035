import React, { useEffect } from "react";
import { Grid , makeStyles } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";


const useStyles=makeStyles((theme)=>({

    parent:{

        [theme.breakpoints.up("xs")]:{
            display:"flex",
            alignItems:"center",
            flexDirection:"column"
        },

        [theme.breakpoints.up("sm")]:{
            display:"flex",
            alignItems:"center",
            flexDirection:"column",
            justifyContent:"center"
        },

        [theme.breakpoints.up("md")]:{
            display:"flex",
            alignItems:"center",
            justifyContent:"flex-end",
            flexDirection:"row"
        },

        
    },


    Title:{
        [theme.breakpoints.up("xs")]:{
            lineHeight:"35.8px",
            color:"white",
            fontSize:"14px",
            textAlign:"center"
        },


        [theme.breakpoints.down("290")]:{
            lineHeight:"35.8px",
            color:"white",
            fontSize:"11px",
            textAlign:"center"
        },


        [theme.breakpoints.up("sm")]:{
            fontSize:"15px",
            lineHeight:"50.8px",
            color:"white",
            textAlign:"center"
        },

        [theme.breakpoints.up("md")]:{
            fontSize:"16px",
            lineHeight:"50.8px",
            color:"white",
            textAlign:"start"
        },

        [theme.breakpoints.up("lg")]:{
            fontSize:"22px",
            lineHeight:"80.8px",
            color:"white",
            textAlign:"start"
        },
       
    },

    textContent:{
       

        [theme.breakpoints.up("xs")]:{
            fontSize:"10px",
            lineHeight:"14.3px",
            color:"white",
            fontWeight:"10px",
            opacity:"50%",
            textAlign:"center",
            alignItems:"center"
        },

        [theme.breakpoints.up("sm")]:{
            fontSize:"12px",
            lineHeight:"22.2px",
            color:"white",
            fontWeight:"400px",
            opacity:"50%",
            textAlign:"center",
        },

        [theme.breakpoints.up("md")]:{
            fontSize:"12px",
            lineHeight:"22.2px",
            color:"white",
            fontWeight:"400px",
            opacity:"50%",
            textAlign:"start"

        },

        [theme.breakpoints.up("lg")]:{
            fontSize:"14px",
            lineHeight:"25.2px",
            color:"white",
            fontWeight:"400px",
            opacity:"50%",
            textAlign:"start"

        },
       
    },

    

    recenterText: {
        

        [theme.breakpoints.up("xs")]:{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
         },

        [theme.breakpoints.up("sm")]:{
           display:"flex",
           alignItems:"center",
           justifyContent:"center"
        },

        [theme.breakpoints.up("md")]:{
            display:"flex",
            alignItems:"center",
            justifyContent:"flex-end"
         },
       
    },

    parentImage: {

        [theme.breakpoints.up("xs")]:{
            // height:"180px",
            display:"flex",
            justifyContent:"flex-end",
            marginTop:"20px"

         },

         [theme.breakpoints.up("sm")]:{
            // height:"265px",
            display:"flex",
            justifyContent:"flex-end",
            marginTop:""

         },
    }
    
}));




const TextImgImgBigger = ({ Animated = false,title,TextContent,img }) => {


  useEffect(() => {
    AOS.init(); 
  }, []);



  const classes= useStyles();

  return (
        <Grid
        container
        item xs={12}
        sm={12}
        className={classes.parent}
        >

                <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                //   sm={5}
                data-aos={Animated ? "slide-right" : ""}
                data-aos-duration={Animated ? "3000" : ""}
                className={classes.recenterText}
                >
                   <Grid item xs={11} sm={9} md={10} lg={11} className={classes.Title}> {title} </Grid>
                   <Grid item xs={11} sm={9} md={10} lg={11} className={classes.textContent} > {TextContent} </Grid>
                </Grid>

                <Grid
                container
                item
                xs={10}
                sm={10}
                md={6}
                //   sm={7}
                className={classes.parentImage}
                data-aos={Animated ? "zoom-in" : ""}
                data-aos-duration={Animated ? "2000" : ""}
                >
                    <Grid item xs={12} sm={12} md={11}>
                        <img src={img} alt="" width="100%"  />
                    </Grid>
                </Grid>
 

               
        </Grid>
  );
};

export default TextImgImgBigger;
