import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import {
  NewsData,
  imgtextbelowdata1,
  imgtextbelowdata2,
  imgtextbelowdata4,
  imgtextbelowdata5,
  largeimagetext,
  largeimagetext2,
  links,
} from "./NewsData";
import ImgTextBelow from "../../components/ImgTextBelow/ImgTextBelow";
import LargeImageTextBelow from "../../components/LargeImageTextBelow/LargeImageTextBelow";
import NewsCenterPage from "../../components/NewsCenterPage/NewsCenterPage";
import { connect } from 'react-redux';
import * as NewsActionCreators from '../../store/actionsCreators/NewsActionCreator';
import AvhLoader from "./Loader";

function News({news1,news2,news3,news4,news5,news6,getNews,getAvhNews}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading,setIsLoading] = useState(true);
  useEffect(()=>{
    getNews();
    getAvhNews();
  },[])

  useEffect(()=>{
    if(news1!== '' && news2 !== '' && news3!== '' && news4!== '' && news5!== '' &&news6!==''){
    setIsLoading(false);
    }
  },[news1,news2,news3,news4,news5,news6])

  const reverseContent = useMediaQuery("(max-width: 600px)");
  return (
    <NewsCenterPage
      whatwedo={NewsData.whatwedo}
      WhiteTitle={NewsData.WhiteTitle}
      AquaTitle={NewsData.AquaTitle}
      BusinessImage={NewsData.BusinessImage}
    >
      {isLoading ? <AvhLoader /> :
      <>
       <Grid container xs={12} justifyContent="center" alignItems="center">
        <Grid item lg={6} md={6} sm={6}>
          <ImgTextBelow
            Animated
            titleOne={news1.whiteTitle}
            titleTwo={news1.greenTitle}
            desc={news1.description}
            image={news1.image}
            link={news1.link}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6}>
          <ImgTextBelow
            Animated
            titleOne={news2.whiteTitle}
            titleTwo={news2.greenTitle}
            desc={news2.description}
            image={news2.image}
            link={news2.link}
          />
        </Grid>
      </Grid>
      <Grid
        xs={12}
        style={
          ({ marginTop: reverseContent ? "0px" : "100px" },
          { marginBottom: "30px" })
        }
      >
        <LargeImageTextBelow
          Animated
          titleOne={news3.whiteTitle}
            titleTwo={news3.greenTitle}
            desc1={news3.description}
            Image={news3.image}
            link={news3.link}
          // link={links.link3}
        />
      </Grid>

      <Grid container xs={12} justifyContent="center" alignItems="center">
        <Grid item lg={6} md={6} sm={6}>
          <ImgTextBelow
            Animated
            titleOne={news4.whiteTitle}
            titleTwo={news4.greenTitle}
            desc={news4.description}
            image={news4.image}
            link={news4.link}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6}>
          <ImgTextBelow
            Animated
            titleOne={news5.whiteTitle}
            titleTwo={news5.greenTitle}
            desc={news5.description}
            image={news5.image}
            link={news5.link}
          />
        </Grid>
      </Grid>
      <Grid
        xs={12}
        style={
          ({ marginTop: reverseContent ? "0px" : "100px" },
          { marginBottom: "30px" })
        }
      >
        <LargeImageTextBelow
          Animated
          titleOne={news6.titleOne}
            titleTwo={news6.titleTwo}
            desc1={news6.desc}
            Image={news6.img}
            link='/news'
          // link={links.link3}
        />
      </Grid>
      </>
}
    </NewsCenterPage>
  );
}
const mapStateToProps = (state) =>{
  return{
      news1: state.NewsR.news1,
      news2: state.NewsR.news2,
      news3: state.NewsR.news3,
      news4: state.NewsR.news4,
      news5: state.NewsR.news5,
      news6: state.NewsR.news6,
  };
}


const mapDispatchToProps = (dispatch) =>{
  return{
      getNews : () => dispatch(NewsActionCreators.getNews()), 
      getAvhNews: ()=> dispatch(NewsActionCreators.getAvhNews())
      
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(News)

