import { Grid , makeStyles } from '@material-ui/core'
import React from 'react'
import checkMark from './checkMark.svg'


const useStyles=makeStyles((theme)=>({

    parent: {

        [theme.breakpoints.up("xs")]:{
            color:"white",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            height:"180px"
        },

        [theme.breakpoints.up("sm")]:{
            color:"white",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            height:"150px"
        },

        [theme.breakpoints.up("md")]:{
            color:"white",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            height:"200px"
        },


        [theme.breakpoints.between("960","1045")]:{
            color:"white",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            height:"180px"
        },

        [theme.breakpoints.up("lg")]:{
            color:"white",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            height:"250px"
        },
       
    },

    parentOfContent:{ 

        [theme.breakpoints.up("xs")]:{
            height:"125px",
            fontSize:"10px",
            height:"130px"
        },

        [theme.breakpoints.up("sm")]:{
            height:"110px",
            fontSize:"10px"
        },

        [theme.breakpoints.up("md")]:{
            height:"115px",
            fontSize:"12px"
        },

        [theme.breakpoints.up("lg")]:{
            height:"155px",
            fontSize:"12px"
        },


    },

    title:{
        [theme.breakpoints.up("xs")]:{
            fontSize:"12px" 
        },

        [theme.breakpoints.up("sm")]:{
            fontSize:"15px",
        },

        [theme.breakpoints.up("md")]:{
            fontSize:"16px" 
        },

        [theme.breakpoints.up("lg")]:{
            fontSize:"22px" 
        },
       
       
    },



    LeftText:{
  
        [theme.breakpoints.up("xs")]:{
            display:"flex",
            alignItems:"center",
            fontSize:"10px",
            color:"white",
            fontWeight:"400px",
            opacity:"50%",
            lineHeight:"12px"
        },

        [theme.breakpoints.up("sm")]:{
            display:"flex",
            alignItems:"center",
            fontSize:"12px",
            color:"white",
            fontWeight:"400px",
            opacity:"50%"
        },

        [theme.breakpoints.up("md")]:{
            display:"flex",
            alignItems:"center",
            fontSize:"12px",
            color:"white",
            fontWeight:"400px",
            opacity:"50%"
        },

        [theme.breakpoints.up("lg")]:{
            display:"flex",
            alignItems:"center",
            fontSize:"14px",
            color:"white",
            fontWeight:"400px",
            opacity:"50%"
        },
       
    },


    
    recenterCheck: {


        [theme.breakpoints.up("xs")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.between("420","479 ")]:{
            marginTop:"-11px"
        },
       

        [theme.breakpoints.between("379","420 ")]:{
            marginTop:"-11px"
        },

        [theme.breakpoints.between("347","379 ")]:{
            marginTop:"-11px"
        },

        [theme.breakpoints.between("333","347")]:{
            marginTop:"-11px"
        },


        [theme.breakpoints.between("307","333")]:{
            marginTop:"-23px"
        },


        [theme.breakpoints.down("308")]:{
            marginTop:"-22px"
        },

        [theme.breakpoints.up("sm")]:{
            display:"flex",alignItems:"center"
        },

        
        [theme.breakpoints.between("599","672")]:{
            display:"flex",alignItems:"center",
        },

        

        [theme.breakpoints.up("md")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.between("959","1044")]:{
            display:"flex",alignItems:"center",marginTop:"-11px"
        },
    },


    recenterCheckLeft: {

        [theme.breakpoints.up("xs")]:{
            display:"flex",alignItems:"center"
        },


        [theme.breakpoints.between("379","420 ")]:{
            marginTop:"-11px"
        },

        [theme.breakpoints.between("347","379 ")]:{
            marginTop:"-11px"
        },


        [theme.breakpoints.between("308","347 ")]:{
            marginTop:"-11px"
        },

        [theme.breakpoints.down("308")]:{
            marginTop:"-11px"
        },

        [theme.breakpoints.up("sm")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.up("md")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.between("959","974")]:{
            display:"flex",alignItems:"center",marginTop:"-17px"
        },
    },

    recenterCheckFinancial:{

        [theme.breakpoints.up("xs")]:{
            display:"flex",alignItems:"center"
        },


       
        [theme.breakpoints.between("347","379 ")]:{
            marginTop:"-11px"
        },

        [theme.breakpoints.between("308","408")]:{
            marginTop:"-11px"
        },

        [theme.breakpoints.down("308")]:{
            marginTop:"-11px"
        },

        [theme.breakpoints.up("sm")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.up("md")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.between("959","974")]:{
            display:"flex",alignItems:"center"
        },
    },


    recenterCheckmarkDebt:{

        [theme.breakpoints.up("xs")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.between("347","370 ")]:{
            marginTop:"-11px"
        },

        [theme.breakpoints.between("308","347 ")]:{
            marginTop:"-11px"
        },

        [theme.breakpoints.down("308")]:{
            marginTop:"-11px"
        },
        
        [theme.breakpoints.up("sm")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.up("md")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.between("959","974")]:{
            display:"flex",alignItems:"center"
        },
    },

    recenterCheckPrivate:{

        [theme.breakpoints.up("xs")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.down("308")]:{
            marginTop:"-11px"
        },
        
        [theme.breakpoints.up("sm")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.up("md")]:{
            display:"flex",alignItems:"center"
        },

        [theme.breakpoints.between("959","974")]:{
            display:"flex",alignItems:"center"
        },
    },

    
  }));


function TextOfLeftImage() {

  const classes= useStyles();
 
    return (
        <Grid container item xs={12} className={classes.parent} >
            <Grid item xs={11} className={classes.title}>We deliver exceptioanl value through:</Grid>
            <Grid  container item xs={12} sm={11} md={11} className={classes.parentOfContent}>
                <Grid container item xs={12}>
                    <Grid container item xs={8} style={{display:"flex",alignItems:"center"}} >
                        <Grid item xs={1} className={classes.recenterCheckmarkDebt}><img src={checkMark} width="60%" height="65%" /></Grid>
                        <Grid item xs={11} className={classes.LeftText}>Debt and equity private placements</Grid>
                    </Grid>
                    <Grid container item xs={4} style={{display:"flex",alignItems:"center"}}>
                        <Grid item xs={2} style={{display:"flex",alignItems:"center"}}><img src={checkMark} width="60%" height="65%" /></Grid>
                        <Grid item xs={10} className={classes.LeftText}>Divestments</Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid container item xs={8} style={{display:"flex",alignItems:"center"}}>
                        <Grid item xs={1} style={{display:"flex",alignItems:"center"}}><img src={checkMark} width="60%" height="65%" /></Grid>
                        <Grid item xs={11} className={classes.LeftText}>M&As</Grid>
                    </Grid>
                    <Grid container item xs={4}  style={{display:"flex",alignItems:"center"}}>
                        <Grid item xs={2} style={{display:"flex",alignItems:"center"}}><img src={checkMark} width="60%" height="65%" /></Grid>
                        <Grid item xs={10} className={classes.LeftText}>IPOs</Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid container item xs={8} style={{display:"flex",alignItems:"center"}}>
                        <Grid item xs={1} className={classes.recenterCheckFinancial}><img src={checkMark} width="60%" height="65%" /></Grid>
                        <Grid item xs={11} className={classes.LeftText}>Financial engineering and restructuring</Grid>
                    </Grid>
                    <Grid container item xs={4}  style={{display:"flex",alignItems:"center"}}>
                        <Grid item xs={2} className={classes.recenterCheckPrivate}><img src={checkMark} width="60%" height="65%" /></Grid>
                        <Grid item xs={10} className={classes.LeftText}>Private equity</Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid container item xs={8} style={{display:"flex",alignItems:"center"}}>
                        <Grid item xs={1} className={classes.recenterCheck}><img src={checkMark} width="60%" height="65%" /></Grid>
                        <Grid item xs={11} className={classes.LeftText}>Operations value enhancements and turnarounds</Grid>
                    </Grid>
                    <Grid container item xs={4}  style={{display:"flex",alignItems:"center"}}>
                        <Grid item xs={2} className={classes.recenterCheckLeft} ><img src={checkMark} width="60%" height="65%" /></Grid>
                        <Grid item xs={10} className={classes.LeftText}>Real estate finance.</Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid container item xs={8} style={{display:"flex",alignItems:"center"}}>
                        <Grid item xs={1} style={{display:"flex",alignItems:"center"}}><img src={checkMark} width="60%" height="65%" /></Grid>
                        <Grid item xs={11} className={classes.LeftText}>Leveraged buyouts</Grid>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TextOfLeftImage

