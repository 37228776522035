import { Grid , makeStyles} from '@material-ui/core'
import React ,{ useEffect } from 'react'
import BusinessPages from '../../../../components/BusinessPages/BusinessPages'
import ImageTextWithCheckMark from '../../../../components/ImgTextWithCheckMark/ImageTextWithCheckMark'
import TextImage from '../../../../components/TextImage/TextImage'
import TextImgImgBigger from '../../../../components/TextImgImgBigger/TextImgImgBigger'
import { BusinessData, TextImgsData } from '../BusinessHome/BusinessData'
import './Business.css'

const useStyles=makeStyles((theme)=>({


  parentCheckmark: {

    [theme.breakpoints.up("xs")]:{
      marginTop:"125px"
    },

    [theme.breakpoints.up("sm")]:{
      marginTop:"100px"
    },

    [theme.breakpoints.up("md")]:{
      marginTop:"0px"
    },

    [theme.breakpoints.up("lg")]:{
      marginTop:"-100px"
    },
  },

  parentTextImage: {
      [theme.breakpoints.up("xs")]:{
        marginTop:"75px",marginBottom:"15px"
      },

      [theme.breakpoints.up("sm")]:{
        marginTop:"65px",marginBottom:"75px"
      },


      [theme.breakpoints.up("md")]:{
        marginTop:"60px",
        marginBottom:"85px"
      },

     
  },

}));

function BusinessLogistics() {

  const classes= useStyles();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <Grid>
      <BusinessPages
        whatwedo={BusinessData.whatwedo}
        WhiteTitle={BusinessData.WhiteTitle}
        AquaTitle={BusinessData.AquaTitle}
        description={BusinessData.description}
        BusinessImage={BusinessData.BusinessImage}
      >
        <Grid item xs={12} className={classes.parentCheckmark}>
          <ImageTextWithCheckMark Animated />
        </Grid>

        <Grid item xs={12} className={classes.parentTextImage} id="imagetexttt">
          <TextImgImgBigger Animated 
            title={TextImgsData.title}
            TextContent={TextImgsData.TextConten}
            img={TextImgsData.Image}
          />

          {/* <TextImage Animated 
            title={TextImgsData.title}
            desc={TextImgsData.TextConten}
            Image={TextImgsData.Image}
          /> */}
        </Grid>
        
      </BusinessPages>

      
    </Grid>

  )
}

export default BusinessLogistics
