// import img from "../BusinessHome/Images/BusinessHome.png";
// import img2 from "../BusinessHome/Images/img.png"
import img from "../FacilitiesManagement/FacilitiesManagementImages/FacilitiesManagementHome.png"
import img2 from "../FacilitiesManagement/FacilitiesManagementImages/textimg.png"

export const FacilitiesManagementData = {

    whatwedo: "WHAT WE DO",
    WhiteTitle: "FACILITIES",
    AquaTitle: "MANAGEMENT",
    description: "AVH provides superior facilities management services across multiple industries: transport, travel, healthcare, education, hospitality, industrial, residential, retail and commercial complexes, and more.",
    BusinessImage: img,
};

export const TextImgsData = {

    title: "Advanced Digital Platform for Facilities Management",
    TextContent: "AVH provides superior facilities management services across multiple industries: transport, travel, healthcare, education, hospitality, industrial, residential, retail and commercial complexes, and more.",
    Image: img2,
};
