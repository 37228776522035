import "./Navbar.css";
import logo from "./logo.png";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <header>
        <div className="container">
          <a className="logo" href="#Home">
            <NavLink to="/" exact>
              <img src={logo} alt="" />
            </NavLink>
          </a>
          <nav>
            <div className="toggle-menu" onClick={this.handleClick}>
              <i
                className={
                  this.state.clicked ? "fas fa-times icon" : "fas fa-bars icon"
                }
              ></i>
            </div>
            <ul
              className={this.state.clicked ? "nav-menu activeee" : "nav-menu"}
            >
              <li>
                {/* <a> */}
                <NavLink
                  to="/"
                  exact
                  style={{ color: `${this.props.colorHome}` }}
                >
                  Home
                </NavLink>
                {/* </a> */}
              </li>
              <li>
                {/* <a> */}
                <NavLink
                  to="/about-us"
                  exact
                  style={{ color: `${this.props.colorAbout}` }}
                >
                  About
                </NavLink>
                {/* </a> */}
              </li>
              <li>
                {/* <a> */}
                <NavLink
                  to="/news-center"
                  style={{ color: `${this.props.colorNewsCenter}` }}
                >
                  News-center
                </NavLink>
                {/* </a> */}
              </li>
              <li>
                {/* <a> */}
                <NavLink
                  to="/business"
                  style={{ color: `${this.props.colorBusiness}` }}
                >
                  Business
                </NavLink>
                {/* </a> */}
              </li>
              <li>
                {/* <a> */}
                <NavLink
                  to="/careers"
                  style={{ color: `${this.props.colorCareers}` }}
                >
                  Careers
                </NavLink>
                {/* </a> */}
              </li>
              <li>
                {/* <a> */}
                <NavLink
                  to="/invest"
                  exact
                  style={{ color: `${this.props.colorInvest}` }}
                >
                  Start-ups
                </NavLink>
                {/* </a> */}
              </li>
              <li>
                {/* <a> */}
                <NavLink
                  to="/contact-us"
                  exact
                  style={{ color: `${this.props.colorContact}` }}
                >
                  Contact
                </NavLink>
                {/* </a> */}
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}
export default Navbar;
