import img from "../BusinessHome/Images/BusinessHome.png";
import img2 from "../BusinessHome/Images/img.png"


export const BusinessData = {

    whatwedo: "WHAT WE DO",
    WhiteTitle: "ASSESTS",
    AquaTitle: "MANAGEMENT",
    description: "AVH investments solutions and financial advisory create wealth for mid-sized corporations, banks, high net-worth individuals, private equity firms and family-owned businesses.",
    BusinessImage: img,
};

export const TextImgsData = {

    title: "Advanced digital platform for Assest Management",
    TextConten: "AVH provides technology solutions built upon deep industry experience. NUASSET is an advanced digital asset management marketplace that paves the way for entirely new models of value creation. it pioneers the best of blockchain-tokenization, dynamicsmart contracts and decentralization - to allow multiple, complex transacttions and legal processes to accur quicjly and easily in one platform.",
    Image: img2,
};
