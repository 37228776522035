import img from "./images/imgN.PNG";
import img1 from "./images/news1.png";
import img2 from "./images/news2.png";
import img3 from "./images/news3.png";
import img4 from "./images/news4.png";
import img5 from "./images/news_int4.png";
import img6 from "./images/news6.png";

export const NewsData = {
  whatwedo: "NEWS CENTER",
  WhiteTitle: "TOP",
  AquaTitle: "NEWS",
  BusinessImage: img,
};

export const imgtextbelowdata1 = {
  img: img1,
  titleOne: "URGENT CARE'S IMPACT DURING THE PANDEMIC AND ",
  titleTwo: "THE FUTURE OF ON-DEMAND CARE",
  desc: "Urgent care clinics saw a 58% increase in visit volumes due to the demand for COVID-19-related, episodic care 2020.How can urgent care clinics retain these new patients and shift the perception of urgent care to a one-stop-shop for on-demand cary....",
};
export const imgtextbelowdata2 = {
  img: img2,
  titleOne: "BLOCKCHAIN.COM CFO SAYS COMPANY COULD IPO IN ",
  titleTwo: "’18-MONTHS, FIRM’S BALANCE SHEET HOLDS BTC, ETH",
  desc: "News reported on Blockchain.com raising $300 million and receiving a post-money valuation of around $5.2 billion. The company has been around for quite some time and when it was called “Blockchain.info” it was established by Ben Reeves way back in 2011. In 2013 it was led by Nicolas Cary...",
};

export const largeimagetext = {
  img: img3,
  titleOne:
    "HAZELVIEW INVESTMENTS LAUNCHES NEW VENTURE CAPITAL FUND FOR INNOVATIVE PROPTECH ",
  titleTwo: "ENTREPRENEURS & MAKES FIRST INVESTMENT",
  desc: "Hazelview Ventures, a venture capital business focused exclusively on partnering with innovative, early-stage PropTech, BuildTech and CleanTech companies. Beyond simply providing funding, Hazelview Ventures offers entrepreneurs the ability to test, refine and scale at an enterprise level, while optimizing the management of Hazelview's real estate properties and investments.",
};

export const imgtextbelowdata4 = {
  img: img4,
  titleOne: "URGENT CARE'S IMPACT DURING THE PANDEMIC AND",
  titleTwo: " THE FUTURE OF ON-DEMAND CARE",
  desc: "Urgent care clinics saw a 58% increase in visit volumes due to the demand for COVID-19-related, episodic care 2020.How can urgent care clinics retain these new patients and shift the perception of urgent care to a one-stop-shop for on-demand cary....",
};
export const imgtextbelowdata5 = {
  img: img5,
  titleOne: "BLOCKCHAIN.COM CFO SAYS COMPANY COULD IPO IN",
  titleTwo: " ’18-MONTHS, FIRM’S BALANCE SHEET HOLDS BTC, ETH",
  desc: "News reported on Blockchain.com raising $300 million and receiving a post-money valuation of around $5.2 billion. The company has been around for quite some time and when it was called “Blockchain.info” it was established by Ben Reeves way back in 2011. In 2013 it was led by Nicolas Cary...",
};

export const largeimagetext2 = {
  img: img6,
  titleOne:
    "HAZELVIEW INVESTMENTS LAUNCHES NEW VENTURE CAPITAL FUND FOR INNOVATIVE PROPTECH",
  titleTwo: " ENTREPRENEURS & MAKES FIRST INVESTMENT",
  desc: "Hazelview Ventures, a venture capital business focused exclusively on partnering with innovative, early-stage PropTech, BuildTech and CleanTech companies. Beyond simply providing funding, Hazelview Ventures offers entrepreneurs the ability to test, refine and scale at an enterprise level, while optimizing the management of Hazelview's real estate properties and investments.",
};

export const links = {
  link1:
    "https://www.healthcareitnews.com/news/urgent-cares-impact-during-pandemic-and-future-demand-care",
  link2:
    "https://todayuknews.com/crypto-currency/blockchain-com-cfo-says-company-could-ipo-in-18-months-firms-balance-sheet-holds-btc-eth-bitcoin-news/",
  link3:
    "https://www.newswire.ca/news-releases/hazelview-investments-launches-new-venture-capital-fund-for-innovative-proptech-entrepreneurs-and-makes-first-investment-821463792.html",
};
