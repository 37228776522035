import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import BusinessPages from "../../../../../src/components/BusinessPages/BusinessPages";

import ImageText from "../../../../components/ImageText/ImageText";
import TextImage from "../../../../components/TextImage/TextImage";

import TextImgImgBigger from "../../../../components/TextImgImgBigger/TextImgImgBigger";
import ImgTextText from "../../../../components/ImgTextText/ImgTextText";
import "./Aviation.css";

import { AviationData, TextImageData, ImageTextData } from "./AviationData";

function Aviation() {
  return (
    <Grid>
      <BusinessPages
        whatwedo={AviationData.whatwedo}
        WhiteTitle={AviationData.WhiteTitle}
        BusinessImage={AviationData.BusinessImage}
      >
        {/* <Grid item xs={12}>
          <ImageText
            Animated
            title={ImageTextData.title}
            desc={ImageTextData.desc}
            Image={ImageTextData.Image}
          />
        </Grid>
        <Grid item xs={12}>
          <TextImage
            Animated
            title={TextImageData.title}
            desc={TextImageData.desc}
            Image={TextImageData.Image}
          />
        </Grid> */}

        <Grid item xs={12} className="aviation-imgtext">
          <ImgTextText
            Animated
            title={ImageTextData.title}
            TextContent={ImageTextData.desc}
            img={ImageTextData.Image}
          />
        </Grid>
        <Grid item xs={12}>
          <TextImgImgBigger
            Animated
            title={TextImageData.title}
            TextContent={TextImageData.desc}
            img={TextImageData.Image}
          />
        </Grid>
      </BusinessPages>
    </Grid>
  );
}
export default Aviation;
