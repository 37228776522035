import img from "./images/im.png";
import img1 from "./images/img2.png";

export const PharmaceuticalData = {
  whatwedo: "WHAT WE DO",
  WhiteTitle: "PHARMACEUTICAL",
  BusinessImage: img,
};

export const LargeImageTextData = {
  title: "Pharmaceutical Expertise",
  desc1:
    "AVH is the licensed distributor of pharmaceutical and pharmacy-only products in the MENA region, improving consumer access to important medications and enhancing healthcare outcomes. Our strengths in technology combine with industry expertise for timely, safe and accurate solutions.",
  Image: img1,
};
