import im from "./images/im.png";

export const MessageCEOData = {
  image: im,
  titleOne: "Message From",
  titleTwo: "CEO",
  descOne:
    "A visionary entrepreneur, Mouataz has deep IT experience and diverse industry know-how, and a deepseated passion for creating new, collaborative ventures. He has leveraged these to fuel AVH success in some of the world’s fastest growing markets.",
  descTwo:
    "Mouataz stays at the forefront of technology and business leadership through academic studies at Harvard School of Business, MIT and London School of Economics.",
  ceo: "Mouataz Zreika",
};
