import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import "./SmallTextImg.css";
import { SmallTextImgData } from "./SmallTextImgData";
import AOS from "aos";
import "aos/dist/aos.css";

const SmallTextImg = ({ Animated = false }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="smalltextimg">
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        data-aos={Animated ? "zoom-in" : ""}
        data-aos-duration={Animated ? "3000" : ""}
      >
        <Grid item xs={11} sm={5}>
          <p className="title">{SmallTextImgData.title}</p>
          <p className="desc">{SmallTextImgData.desc}</p>
        </Grid>

        <Grid item xs={7} sm={5}>
          <img src={SmallTextImgData.image} alt="" width="100%" />
        </Grid>
      </Grid>
    </section>
  );
};

export default SmallTextImg;
