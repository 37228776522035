import React, { useState } from "react";
import { dataSlider } from "./dataSlider";
import "./Slider.css";
import leftArrow from "./images/arrowLeft.png";
import rightArrow from "./images/arrowRight.png";

const Slider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const length = slides.length;
  const delay = 5000;

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrent((prevIndex) =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [current]);

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <div className="slider-section">
      <section className="slider">
        {dataSlider.map((slide, index) => {
          return (
            <div
              className={index === current ? "slide active" : "slide"}
              key={index}
            >
              {index === current && (
                <img
                  className="img-slider"
                  src={slide.image}
                  alt="AVH Holding"
                />
              )}
              {index === current && (
                <div className="content-container">
                  {/* <p className="slideNb">{slide.nb}</p> */}
                  <p className="title1">{slide.titleOne}</p>
                  <p className="title2">{slide.titleTwo}</p>
                  <p className="desc">{slide.description}</p>
                  {/* <button className="learn-more">Learn more</button> */}
                </div>
              )}
            </div>
          );
        })}
      </section>
      <div className="slideshowDots-container">
        <div className="slideshowDots">
          {dataSlider.map((slide, idx) => (
            <div
              key={idx}
              className={`dots-container${current === idx ? " active" : ""}`}
              onClick={() => {
                setCurrent(idx);
              }}
            >
              <p className="title1">{slide.titleOne}</p>
              <p className="title2">{slide.titleTwo}</p>
              {/* <p className="slideNb">{slide.nb}</p> */}
            </div>
          ))}

          <span className="arrows-container">
            {/* <i
              className="fas fa-long-arrow-alt-left left-arrow"
              onClick={prevSlide}
            ></i>
            <i
              className="fas fa-long-arrow-alt-right right-arrow"
              onClick={nextSlide}
            /> */}
            <img src={leftArrow} className="left-arrow" onClick={prevSlide} />
            <img src={rightArrow} className="right-arrow" onClick={nextSlide} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Slider;
