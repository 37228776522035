import { Grid , makeStyles} from '@material-ui/core'
import React, {useEffect} from 'react'
import BusinessPages from '../../../../../src/components/BusinessPages/BusinessPages'
import TextImgImgBigger from '../../../../../src/components/TextImgImgBigger/TextImgImgBigger'

import { FacilitiesManagementData,TextImgsData } from './FacilitiesManagementData'
import './Facilities.css'

const useStyles=makeStyles((theme)=>({


  imgText: {
      [theme.breakpoints.up("xs")]:{
        marginTop:"100px"
      },

      [theme.breakpoints.down("300")]:{
        marginTop:"150px"
      },

      [theme.breakpoints.up("sm")]:{
        marginTop:"70px",
        marginBottom:"30px"

      },

      [theme.breakpoints.between("600","700")]:{
        marginTop:"90px",
        marginBottom:"30px"

      },

      

      [theme.breakpoints.up("md")]:{
        marginTop:"-10px",
        marginBottom:"80px"
      },
  },

}));

function FacilitiesManagement() {

  const classes= useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 


  return (
    <Grid>
      <BusinessPages
        whatwedo={FacilitiesManagementData.whatwedo}
        WhiteTitle={FacilitiesManagementData.WhiteTitle}
        AquaTitle={FacilitiesManagementData.AquaTitle}
        description={FacilitiesManagementData.description}
        BusinessImage={FacilitiesManagementData.BusinessImage}
      >


      <Grid item xs={12} className={classes.imgText} id="imagetexttttt" >
        <TextImgImgBigger Animated 
          title={TextImgsData.title}
          TextContent={TextImgsData.TextContent}
          img={TextImgsData.Image}
        />
      </Grid>
      </BusinessPages>
    </Grid>

  )
}

export default FacilitiesManagement
