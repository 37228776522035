import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useMediaQuery } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import "./LargeImageText.css";

function LargeImageText({
  Animated = false,
  title,
  title2,
  desc1,
  desc2,
  Image,
}) {
  useEffect(() => {
    AOS.init();
  }, []);

  const mobileSize = useMediaQuery("(max-width: 750px)");
  const reverseContent = useMediaQuery("(max-width: 600px)");
  return (
    <section className="largeimageText">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction={reverseContent ? "column-reverse" : null}
      >
        <Grid
          item
          xs={12}
          style={{
            padding: mobileSize ? (reverseContent ? "20px" : "20px") : "40px",
          }}
          data-aos={Animated ? "slide-right" : ""}
          data-aos-duration={Animated ? "3000" : ""}
        >
          <div className="img-cont">
            <img src={Image} alt="img" width="100%" />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            paddingTop: mobileSize
              ? reverseContent
                ? "20px"
                : "20px"
              : "40px",
            paddingLeft: mobileSize
              ? reverseContent
                ? "20px"
                : "20px"
              : "40px",
            paddingRight: mobileSize
              ? reverseContent
                ? "20px"
                : "20px"
              : "40px",
          }}
          data-aos={Animated ? "zoom-in" : ""}
          data-aos-duration={Animated ? "2000" : ""}
        >
          <div className="text-cont">
            <p className="title">{title}</p>
            {title2 != "" ? <p className="title2">{title2}</p> : null}
            <p className="desc1">{desc1}</p>
            {desc2 != "" ? <p className="desc2">{desc2}</p> : null}
          </div>
        </Grid>
      </Grid>
    </section>
  );
}
export default LargeImageText;
