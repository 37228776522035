import { Grid , makeStyles} from '@material-ui/core'
import React, {useEffect} from 'react'
import CareersPage from '../../../components/CareersPage/CareersPage';
import CreersTextComponent from '../../../components/CareersTextComponent/CreersTextComponent';
import { CareersData, CareersText1, CareersText2, CareersText3 } from './CareersData';



const useStyles=makeStyles((theme)=>({


  imgText1: {

    
      

      

      [theme.breakpoints.up("xs")]:{
        marginTop:"15px",
        marginBottom:"45px",

      },

      [theme.breakpoints.down("465")]:{
        marginTop:"55px",
        marginBottom:"45px",
  
      },

      [theme.breakpoints.down("370")]:{
        marginTop:"95px"
      },

      [theme.breakpoints.down("328")]:{
        marginTop:"175px"
      },

      [theme.breakpoints.up("sm")]:{
        marginTop:"-115px",
        marginBottom:"45px",

      },

      [theme.breakpoints.between("720","830")]:{
        marginTop:"-50px",
        marginBottom:"120px"
      },

      [theme.breakpoints.between("599","720")]:{
        marginTop:"20px",
        marginBottom:"120px"
      },

      [theme.breakpoints.up("md")]:{
        marginTop:"-160px",
        // marginBottom:"390px"
      },

      
      // [theme.breakpoints.up("md")]:{
      //   marginTop:"-10%",
      //   marginBottom:"130px"
      // },   

      [theme.breakpoints.between("960","1124")]:{
        marginTop:"-50px",
        marginBottom:"120px"
      },
      
      [theme.breakpoints.up("lg")]:{
        marginTop:"-280px",
        marginBottom:"150px"
      },

  },

  imgText:{

    [theme.breakpoints.up("xs")]:{
      marginTop:"30px"
    },
    
    [theme.breakpoints.up("sm")]:{
      marginTop:"33px"
    },

    [theme.breakpoints.up("md")]:{
      marginTop:"50px"
    },

    [theme.breakpoints.up("lg")]:{
      marginTop:"90px"
    },
  }

}));

function Careers() {

  const classes= useStyles();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 


  return (
    <Grid>
        <CareersPage
            whatwedo={CareersData.whatwedo}
            WhiteTitle={CareersData.WhiteTitle}
            AquaTitle={CareersData.AquaTitle}
            description={CareersData.description}
            BusinessImage={CareersData.BusinessImage}
        >

          <Grid item xs={12} className={classes.imgText1} >

            <Grid item xs={12} className={classes.imgText2} >
               
                <CreersTextComponent 
                  title={CareersText1.title}
                  desc={CareersText1.TextContent}
                  link={CareersText1.link}

                />
            </Grid>

            <Grid item xs={12} className={classes.imgText} >

                <CreersTextComponent 
                  title={CareersText2.title}
                  desc={CareersText2.TextContent}
                  link={CareersText2.link}
                />
            </Grid>

            <Grid item xs={12} className={classes.imgText} >

                <CreersTextComponent 
                  title={CareersText3.title}
                  desc={CareersText3.TextContent}
                  link={CareersText3.link}
                />
            </Grid>

          </Grid>

         </CareersPage>
    </Grid>
  )
}

export default Careers
