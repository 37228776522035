import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import CareersPage from "../../../components/CareersPage/CareersPage";
import SeniorText from "../../../components/SeniorText/SeniorText";
import { SeniorAccountData } from "./SeniorAccountData";
import "./SeniorAccount.css";
import SendCvForm from "../../../components/SeniorText/SendCvForm";

const useStyles = makeStyles((theme) => ({
  parentContent: {
    [theme.breakpoints.up("xs")]: {
      marginTop: "160px",
    },

    [theme.breakpoints.between("430", "600")]: {
      marginTop: "50px",
    },

    [theme.breakpoints.down("325")]: {
      marginTop: "250px",
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: "33px",
    },

    [theme.breakpoints.between("600", "690")]: {
      marginTop: "83px",
    },

    [theme.breakpoints.between("600", "630")]: {
      marginTop: "110px",
    },

    [theme.breakpoints.up("md")]: {
      marginTop: "-140px",
    },

    [theme.breakpoints.up("960", "1000")]: {
      marginTop: "-100px",
    },

    [theme.breakpoints.up("lg")]: {
      marginTop: "-260px",
    },
  },

  parentResponsivee: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },

    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
    },
  },

  recenterCvForm: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
      marginTop: "50px",
    },

    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "0px",
    },
  },
}));

function SeniorAccount() {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid>
      <CareersPage
        whatwedo={SeniorAccountData.whatwedo}
        WhiteTitle={SeniorAccountData.WhiteTitle}
        AquaTitle={SeniorAccountData.AquaTitle}
        description={SeniorAccountData.description}
        BusinessImage={SeniorAccountData.BusinessImage}
      >
        <Grid
          container
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={classes.parentContent}
        >
          <Grid item xs={11} className={classes.parentResponsivee}>
            <Grid container item xs={12} md={6}>
              <SeniorText />
            </Grid>
            <Grid
              container
              item
              xs={10}
              md={6}
              className={classes.recenterCvForm}
            >
              <Grid
                item
                xs={12}
                sm={11}
                className="SendCvBox"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "30px 10px",
                }}
              >
                <SendCvForm />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CareersPage>
    </Grid>
  );
}

export default SeniorAccount;
