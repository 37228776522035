import axios from 'axios';
import * as actionTypes from '../actions/actions';
import { server }from './constants'

export const getAvhNews = () => {
    
    return dispatch =>{       
        var link = server+'/avh/api/avhNews';
       axios.get(link)
            .then(res=>{
                    console.log(res.data)
                    dispatch(getAvhNewsEnd(res.data));
                                
            }).catch(err => {
                console.log("Something wrong while getting News ...");
            })
        
    }
}

export const getAvhNewsEnd = (data) => {
       
    return{
        type: actionTypes.AvhNews.GET_AVH_NEWS_DETAILS,
        newsA: data
    }
}

export const getNews = () => {
    
    return dispatch =>{       
        var link = server+'/avh/api/getTopNews';
       axios.get(link)
            .then(res=>{
                    console.log(res.data)
                    dispatch(getNewsEnd(res.data));
                                
            }).catch(err => {
                console.log("Something wrong while getting News ...");
            })
        
    }
}

export const getNewsEnd = (data) => {
    var news1 = {}
    var news2 = {}

    for(let i = 0 ; i< data.length ; i++){
        if(data[i].position === 1){
            news1 = data[i]
        }else if(data[i].position === 2){
            news2 = data[i]
        }
    }
    

    return{
        type: actionTypes.AvhNews.GET_MORE_NEWS,
        news1: news1,
        news2: news2,
    }
}
