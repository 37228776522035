import { ContactUsHomeData } from "./ContactUsHomeData";
import "./ContactUsHome.css";
function ContactUsHome() {
  return (
    <section className="ContactUsHome">
      <img src={ContactUsHomeData.image}></img>
      <div className="news-overlay">
        <p className="pretitle">{ContactUsHomeData.whatwedo}</p>
        <p className="title2">{ContactUsHomeData.AquaTitle} </p>
      </div>
    </section>
  );
}
export default ContactUsHome;
