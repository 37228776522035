import img from "./images/img.png";
import img2 from "./images/img2.png";

export const HealthCareData = {
  whatwedo: "WHAT WE DO",
  WhiteTitle: "HEALTHCARE",
  BusinessImage: img,
};
export const ImageTextData = {
  title: "Advanced Digital Platform for Health Care",
  desc: "AVH supports major hospitals worldwide with digital operational models to improve patient outcomes, enhance efficiency and reduce healthcare costs NUMEDIC brings together hospitals and health systems, patient care teams, patients, insurance and life sciences companies on a secure, sophisticated digital platform. Seamless flow of critical data enhances patient outcomes, delivers services more efficiently and competitively, and lowers operational costs.",
  Image: img2,
};
