import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { MessageCEOData } from "./MessageCEOData";
import "./MessageCEO.css";

const MessageCEO = ({ Animated = false }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="messageceo">
      <Grid container spacing={5} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={9}
          sm={5}
          md={3}
          lg={3}
          xl={3}
          data-aos={Animated ? "zoom-in" : ""}
          data-aos-duration={Animated ? "3000" : ""}
        >
          <img src={MessageCEOData.image} alt="" width="100%" />
        </Grid>

        <Grid
          item
          xs={9}
          sm={5}
          md={8}
          lg={8}
          xl={8}
          data-aos={Animated ? "zoom-out" : ""}
          data-aos-duration={Animated ? "1500" : ""}
        >
          <p className="title1">
            {MessageCEOData.titleOne}{" "}
            <span className="title2">{MessageCEOData.titleTwo}</span>
          </p>
          <p className="desc1">{MessageCEOData.descOne}</p>
          <p className="desc2">{MessageCEOData.descTwo}</p>
          <p className="ceo-name">{MessageCEOData.ceo}</p>
        </Grid>
      </Grid>
    </section>
  );
};
export default MessageCEO;
