import location from "./images/location.png";
import call from "./images/call.png";
import email from "./images/email.png";

export const ContactUsInfoData1 = {
  icon: location,
  title1: "Come by at:",
  title2: "Level 20,",
  title3: " 109 Pitt Street, Sydney, NSW, 2000.",
};
export const ContactUsInfoData2 = {
  icon: email,
  title1: "Drop us an email at:",
  title2: "info@anchor.holdings",
};
export const ContactUsInfoData3 = {
  icon: call,
  title1: "Call us at:",
  title2: "AU: +61 2 8624 7500",
};

// Level 20, 109 Pitt Street, Sydney, NSW, 2000. Phone (AU): +61 2 8624 7500
