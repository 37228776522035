import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import img from './checkMark.svg' 



const useStyles=makeStyles((theme)=>({


    title:{

        [theme.breakpoints.up("xs")]:{
            fontSize:"13px",
            fontWeight:500
        },

        [theme.breakpoints.up("sm")]:{
            fontSize:"18px",
            fontWeight:500
        },
    },

    MarginBetweenTtileP:{

        [theme.breakpoints.up("xs")]:{
            marginTop:"15px"
        },

        [theme.breakpoints.up("sm")]:{
            marginTop:"20px"
        },

        [theme.breakpoints.up("lg")]:{
           marginTop:"15px"
        },
    },

   

    parentText: {
  
        [theme.breakpoints.up("xs")]:{
            fontSize:"10px",
            opacity:"50%",
            fontWeight:400,
            display:"flex",
         
        },
    

        [theme.breakpoints.up("sm")]:{
            fontSize:"12px",
            opacity:"50%",
            fontWeight:400
        },
    
        [theme.breakpoints.up("md")]:{
            fontSize:"12px",
            opacity:"50%",
            fontWeight:400
        },
    },

    recenterCheckMark: {
        [theme.breakpoints.up("lg")]:{
            display:"flex",
            // alignItems:"center"
        },
    },

    secondeParagraph: {

        [theme.breakpoints.up("xs")]:{
            marginTop:"30px",

        },


        [theme.breakpoints.up("sm")]:{
            marginTop:"0px",

        },


        [theme.breakpoints.up("md")]:{
            marginTop:"20px",
        },

        [theme.breakpoints.up("lg")]:{
            marginTop:"50px"
        },
    },

    prentResponsive: {
        [theme.breakpoints.up("xs")]:{
            display:"flex",
            flexDirection:"row",
            marginLeft:"0px"

        },

        [theme.breakpoints.up("sm")]:{
            display:"flex",
            flexDirection:"row",
            marginLeft:"0px"

        },

        [theme.breakpoints.up("md")]:{
            display:"flex",
            flexDirection:"row",
            marginLeft:"20px"


        },

        [theme.breakpoints.up("lg")]:{
            display:"flex",
            flexDirection:"row",
            marginLeft:"30px"


        },
    },

    
  
  
  }));


function SeniorText() {

const classes = useStyles();

    return (
        <Grid container item xs={12} style={{color:"white"}} className={classes.prentResponsive}>
            
            <Grid  item xs={12} sm={6} md={11} className={classes.recentertextwith}>
                <Grid item xs={10}  md={12} className={classes.title}>Resbonsibilites</Grid>
                <Grid container item xs={12} sm={10} md={12} spacing={1} className={classes.MarginBetweenTtileP}>
                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1} > <img src={img} width="18px" height="18px" /> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Overseeing a team of account executives to ensure that sales objectives are achieved  </Grid>
                    </Grid>
                    
                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Setting reasonable sales quotas to be achieved by the sales team </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Developing long-lasting relationships with new customers </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Managing existing customer accounts by ensuring that existing customers remain satisfied with company products and services </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Developing and implementing effective account plans to retain existing customers </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Identifying customer needs and communicating how company products and services fulfill those needs </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Developing solid sales proposals to convince potential and existing customers to purchase company products and services </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Collecting customer feedback to determine product and service shortfalls </Grid>
                    </Grid>
                </Grid>
            </Grid>



            <Grid  item xs={12} sm={6} md={12} className={classes.secondeParagraph}>
                <Grid item xs={10} md={12} className={classes.title}>Requirements</Grid>
                <Grid container item xs={12} sm={10} md={12} spacing={1} className={classes.MarginBetweenTtileP}>
                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px" /> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Bachelor's degree in marketing, communications, business administration or management, or related field  </Grid>
                    </Grid>
                    
                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Proven experience working as a senior account executive </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Proficiency in all Microsoft Office applications and Customer Relationship Management (CRM) software </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Outstanding negotiation and consultative sales skills </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Excellent leadership and management skills </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Strong analytical and problem-solving skills </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Effective communication skills </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.recenterCheckMark}>
                        <Grid item xs={1}> <img src={img} width="18px" height="18px"/> </Grid>
                        <Grid item xs={11} className={classes.parentText}> Exceptional customer service skills </Grid>
                    </Grid>
                </Grid>
            </Grid>



        </Grid>
    )
}

export default SeniorText
