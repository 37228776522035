import React, { useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import { ImgTextData } from "./ImgTextData";
import "./imgtext.css";

const ImgText = ({ Animated = false }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const mobileSize = useMediaQuery("(max-width: 750px)");
  const reverseContent = useMediaQuery("(max-width: 600px)");

  return (
    <div className="imgtext">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction={reverseContent ? "column-reverse" : null}
      >
        <Grid
          item
          xs={11}
          sm={6}
          style={{
            padding: mobileSize ? (reverseContent ? "0px" : "20px") : "40px",
          }}
          data-aos={Animated ? "zoom-in" : ""}
          data-aos-duration={Animated ? "3000" : ""}
        >
          <img src={ImgTextData.image} alt="" width="100%" />
        </Grid>
        <Grid
          item
          xs={10}
          sm={6}
          data-aos={Animated ? "slide-left" : ""}
          data-aos-duration={Animated ? "2000" : ""}
        >
          <p className="title1">{ImgTextData.titleOne}</p>
          <p className="title2">{ImgTextData.titleTwo}</p>
          <p className="desc">{ImgTextData.desc}</p>
          <button className="learn-more">Learn more</button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImgText;
