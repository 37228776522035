import { Grid , makeStyles} from '@material-ui/core'
import React, {useEffect} from 'react'
import BusinessPages from '../../../../components/BusinessPages/BusinessPages';
import TextImgImgBigger from '../../../../components/TextImgImgBigger/TextImgImgBigger';
import { RetailData , TextImgsRetail } from './RetailData';
import './Retail.css'


const useStyles=makeStyles((theme)=>({


  imgText: {

     


      [theme.breakpoints.up("xs")]:{
        marginTop:"70px"
      },

      [theme.breakpoints.down("370")]:{
        marginTop:"150px"
      },

      [theme.breakpoints.up("sm")]:{
        marginTop:"75px",
        marginBottom:"50px"
      },


      [theme.breakpoints.up("md")]:{
        marginTop:"-50px",
        marginBottom:"100px"
      },

  },

}));

function Retail() {

  const classes= useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <Grid>
      <BusinessPages
        whatwedo={RetailData.whatwedo}
        WhiteTitle={RetailData.WhiteTitle}
        AquaTitle={RetailData.AquaTitle}
        description={RetailData.description}
        BusinessImage={RetailData.BusinessImage}
      >


      <Grid item xs={12} className={classes.imgText} id="imagetexRetail">
        <TextImgImgBigger Animated 
          title={TextImgsRetail.title}
          TextContent={TextImgsRetail.TextContents}
          img={TextImgsRetail.Image}
        />
      </Grid>
      </BusinessPages>
    </Grid>

  )
}

export default Retail
