import React from "react";
import { connect } from 'react-redux'
import * as TestActionCreators from '../store/actionsCreators/TestActionCreator';


function Test({email, password,status,getTestInfo,testSubmit}){

    const handleSubmit = (e) =>{
        e.preventDefault();
        testSubmit(email,password);
    }

    const handleChange = (e) =>{
        getTestInfo(e.target.name,e.target.value);  
    }

    return (

        <div style={{backgroundColor: "white"}}>
            <form onSubmit={handleSubmit}>
           Email <input type="text" name="email" value={email} onChange={handleChange}/> <br/><br/><br/>
           password <input type="password" name="password" value={password} onChange={handleChange}/><br/>
           <button type="submit" > Send </button>

           <p>{status}</p>
           </form>
        </div>
    );
}
const mapStateToProps = (state) =>{
    return{
        email: state.TestR.email,
        password: state.TestR.password,
        status: state.TestR.status,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        getTestInfo : (n,v) => dispatch(TestActionCreators.getTestInfo(n,v)),
        testSubmit : (email,password) =>dispatch(TestActionCreators.testSubmit(email,password)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Test);