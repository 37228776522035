import im01 from './images/01.png'
import im02 from './images/02.png'
import im03 from './images/03.png'
import im04 from './images/04.png'
import im05 from './images/05.png'

export const dataSlider = [
    {
        nb: '01',
        titleOne: 'ANCHOR',
        titleTwo: 'VENTURES HOLDING',
        image: im01,
        description: 'Anchor Ventures Holding (AVH) is a private holding company and manages a global portfolio of businesses present across virtually all key sectors. AVH drives prosperity in an uncertain world with its unique mix of industry expertise and digital prowess, powered by a vast global partner network',
    },
    {
        nb: '02',
        titleOne: 'INTELLIGENCE',
        titleTwo: 'BUSINESS SOLUTIONS',
        image: im04,
        description: 'Our leadership in diverse fields enables customized, expert solutions with well rounded support.'
    },
    {
        nb: '03',
        titleOne: 'ONE-STOP',
        titleTwo: 'SOLUTIONS',
        image: im03,
        description: 'Partner with AVH for complete, fully integrated solutions and achieve highest impact with less use of resources.',
    },
    {
        nb: '04',
        titleOne: 'LEADERS',
        titleTwo: 'IN TECHNOLOGY',
        image: im02,
        description: 'Many of our businesses hold top industry and ISO certifications.',
    },
    {
        nb: '05',
        titleOne: 'CERTIFIED TO',
        titleTwo: 'HIGHEST STANDARDS',
        image: im05,
        description: 'Many of our businesses hold top industry and ISO certifications.',
    }
];