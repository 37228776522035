
import axios from 'axios';
import * as actionTypes from '../actions/actions';
import { server }from './constants'

export const getContactInfo = (name, value) => {
    return{
        type: actionTypes.Contact.GET_CONTACT_INFO,
        name: name,
        value: value
    }
}

export const contactUs = (fname,lname,phone,email,message) => {
    return dispatch =>{
        dispatch(contactUsStart());
        const params = {"firstName":fname,
                        "lastName":lname,
                        "phone":phone,
                        "email":email,
                        "message":message
                    }
        
            axios.post(server+"/avh/api/contactus",params,{ headers: { "Content-Type": "application/json", }, })

                .then(res=>{
                    
                    dispatch(contactUsEnd(res.data));
                         
                         
                        }).catch(err => {
                            console.log("Failed to contact us. Please try again later ...");
                        })                                                                                                                                        
                }
}

export const contactUsStart = () => {

    return{
        type: actionTypes.Contact.CONTACT_START
    }

}

export const contactUsEnd = (data) => {

    return{
        type: actionTypes.Contact.CONTACT_END,
        dt: data
    }
}

