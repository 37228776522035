import { Grid } from "@material-ui/core";
import "./ServiceCard.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useHistory } from "react-router";

function ServiceCard(props) {
  const history = useHistory();
  const handleClick = (test) => {
    history.push({
      pathname: test,
    });
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      key={props.title}
      data-aos={props.index % 2 === 0 ? "zoom-in" : "zoom-out"}
      data-aos-duration="2000"
    >
      <div className="card-cont" onClick={() => handleClick(props.url)}>
        <div>
          <img className="card-img" src={props.icon} alt=""></img>
        </div>
        <div className="card-title">
          <p>{props.title}</p>
        </div>
        <div>
          <button className="learn-more">Learn more</button>
        </div>
      </div>
    </Grid>
  );
}
export default ServiceCard;
