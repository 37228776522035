import * as actionTypes from '../actions/actions';

const initialState = {
    email: '',
    password: '',
    status: '',
}


const TestR = (state = initialState, action) =>{
    switch (action.type) {
        case actionTypes.TestAction.GET_INFO_TEST:
            return {...state, [action.name]: action.value}

        case actionTypes.TestAction.SUBMIT_TEST:
            return {...state, status: action.dataR}
    default:
        return state
    }
}
export default TestR;