import img from './LogisticsAndTransportImages/LogisticAndManagementImg.png'
import img2 from './LogisticsAndTransportImages/TexttImg.png'

export const LogisticsAndTransportt = {

    whatwedo: "WHAT WE DO",
    WhiteTitle: "LOGISTICS",
    AquaTitle: "& TRANSPORT",
    description: "AVH logistics and transportation solutions guarantee safe, secure and fast delivery of cargo by all modes of transport: road, train, ocean, and air. Our expert logistics personnel and wide network of major carriers ensure cargo arrives in the right place, at the right time. AVH combines best-in-class services with digital know-how to accelerate growth in the sector.",
    BusinessImage: img,
};

export const TextImgsData = {

    title: "Advanced Digital Platform for Logistics & Transport",
    TextContent: "NULOGISTICS is a state-of-the-art digital marketplace that connects all shippers and services providers on a single platform. Supplier search, quote comparison and procurement of any transport mode is fast and easy, underpinned by robust, automated data processing and workflows.",
    Image: img2,
};
