import img from './EducationImages/im1.png'
import img2 from './EducationImages/im2.png'
import img3 from './EducationImages/im3.png'


export const EducationData = {

    whatwedo: "WHAT WE DO",
    WhiteTitle: "EDUCATION",
    BusinessImage: img,
};


export const ImgTextData = {

    title: "Next-Level Education Services",
    TextContent: "AVH helps universities around the world deliver state-of-the-art virtual learning experiences. We make education delivery more efficient for providers, and more accessible to students, thereby expanding their learning and future prospects.",
    ImageText: img2,
};

export const TextImgsData = {

    title2: "Advanced digital University Platform" ,
    TextContent2: "NUVERSITY realizes the full potential of virtual university learning, with COVID-19 only accelerating the rising demand for digital education. Developed in collaboration with world-leading educators, the NUVERSITY platform enhances learning outcomes through immersive engagement, active participation and deep collaboration between students and teaching staff.",
    TextImage: img3,
};

