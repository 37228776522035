
import img from './Images/careeresImage.png'
// import img2 from './GovernmentImages/ImgText.png'

export const CareersData = {

    whatwedo: "Careers",
    WhiteTitle: "CAREERS",
    AquaTitle: "AT AVH",
    description: "Anchor Ventures Holding (AVH) adopts a unique approach for different sectors that it works in, ranging from telecommunications, healthcare, education, aviation, insurance, trading, banking and logistics. It is creating a meaningful impact in all kinds of industries and sectors. Anchor Ventures Holding (AVH) manages a diversified portfolio of global companies and prepares them to emerge as market leaders across industries.",
    BusinessImage: img,
};

export const CareersText1 = {

    title: "Senior Art Directory",
    TextContent: "Senior Art Director is responsible for translating marketing objectives into creative strategies, design, and plans. As well manages graphic design, website development, collateral development, copywriting, advertising, and promotions. The Ideal candidate will be self-motivated, resourceful, and have impeccable design and leadership skills.",
    link: "/seniorArt",
};

export const CareersText2 = {

    title: "Senior Content Writer",
    TextContent: "The senior content writer is effectively demonstrated for exceptional writing abilities and great attention to details. Ultimately, a competent senior copywriter will be highly experienced in creating engaging content for social media and in charge of influencing consumers by delivering a clear message, as well as using their initiative to seek out new trends and incorporate them into campaigns.",
    link: "/seniorWriter",
};

export const CareersText3 = {

    title: "Senior Account Executive",
    TextContent: "The senior account executive's responsibilities include building and maintaining a sales pipeline with a high conversation rate, attending sales meetings to provide progress updates, and researching industry trends. Identify new sales opportunities through networking initiatives and customer referrals, adept at analysing sales performance metrics and negotiating contracts. Ultimately, a senior account executive should demonstrate excellent communication, management, and customer service skills at all times.",
    link: "/seniorAccount",
};
