import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import "./SmallImgText.css";
import { SmallImgTextData } from "./SmallImgTextData";
import AOS from "aos";
import "aos/dist/aos.css";

const SmallImgText = ({ Animated = false }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const reverseContent = useMediaQuery("(max-width: 600px)");
  return (
    <section className="smallimgtext">
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        direction={reverseContent ? "column-reverse" : null}
        data-aos={Animated ? "zoom-in" : ""}
        data-aos-duration={Animated ? "3000" : ""}
      >
        <Grid item xs={9} sm={5} md={5} lg={5}>
          <img src={SmallImgTextData.image} alt="" width="100%" />
        </Grid>

        <Grid className="grid" item xs={11} sm={5} md={5} lg={5}>
          <div>
            <p className="title">{SmallImgTextData.title}</p>
            <p className="desc">{SmallImgTextData.desc}</p>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default SmallImgText;
