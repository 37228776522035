import * as actionTypes from '../actions/actions';

const initialState = {
    news1: '',
    news2: '',
    news3: '',
    news4: '',
    news5: '',
    news6: '',
    
    
}

const NewsR =  (state = initialState, action) => {

    switch (action.type) { 
        case actionTypes.News.GET_NEWS :
            return { ...state,news1: action.news1,news2: action.news2,news3: action.news3,news4: action.news4,news5: action.news5}  
            
        case actionTypes.News.GET_AVH_NEWS :
            return { ...state, news6 : action.newsA  }

        default:
            return state
    }
}

export default NewsR;