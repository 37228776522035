import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import NewsR from './store/reducers/NewsReducer';
import AvhNewsR from './store/reducers/AvhNewsReducer';
import ContactR from './store/reducers/ContactReducer';
import SendCvR from './store/reducers/SendCvReducer';
import StartUpR from './store/reducers/StartUpReducer';
import TestR from './store/reducers/TestReducer';


const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const reducer = combineReducers(
  {
    NewsR: NewsR,
    AvhNewsR: AvhNewsR,
    ContactR: ContactR,
    SendCvR: SendCvR,
    StartUpR: StartUpR,
    TestR: TestR
  }
)

const store = createStore(reducer, composeEnhancers(
  applyMiddleware(thunk)
));

ReactDOM.render(
  <BrowserRouter>
   <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
