import { Grid } from "@material-ui/core";
import React from "react";
import BusinessPages from "../../../../../src/components/BusinessPages/BusinessPages";
import LargeImageText from "../../../../components/LargeImageText/LargeImageText";
import { LargeImageTextData, PharmaceuticalData } from "./PharmaceuticalData";

function Pharmaceutical() {
  return (
    <Grid>
      <BusinessPages
        whatwedo={PharmaceuticalData.whatwedo}
        WhiteTitle={PharmaceuticalData.WhiteTitle}
        description={PharmaceuticalData.description}
        BusinessImage={PharmaceuticalData.BusinessImage}
      >
        <Grid item xs={12}>
          <LargeImageText
            title={LargeImageTextData.title}
            desc1={LargeImageTextData.desc1}
            Image={LargeImageTextData.Image}
          />
        </Grid>
      </BusinessPages>
    </Grid>
  );
}

export default Pharmaceutical;
