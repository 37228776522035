import * as actionTypes from '../actions/actions';

const initialState = {
    firstName: '',
    lastName: '',
    headLine: '',
    email: '',
    phone: '',
    address: '',
    coverLetter: '',
    resume: '',
    loading: false,
    error: '',
    status: ''  
}

const SendCvR =  (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SendCv.GET_SEND_INFO :
            return { ...state, [action.name] : action.value  }

        case actionTypes.SendCv.SEND_START :
            return { ...state,loading: true}

        case actionTypes.SendCv.SEND_END :
            return { ...state,loading: false, status: action.dt}  
                    
        default:
            return state
    }
}

export default SendCvR;