import { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import ImgTextBelow from "../../components/ImgTextBelow/ImgTextBelow";
import LargeImageTextBelow from "../../components/LargeImageTextBelow/LargeImageTextBelow";
import NewsCenterPage from "../../components/NewsCenterPage/NewsCenterPage";
import { connect } from 'react-redux';
import * as NewsActionCreators from '../../store/actionsCreators/NewsActionCreator';
import RingLoader from 'react-spinners/RingLoader'
function AvhLoader() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section style={{width:"100%", height: "100%",display:"flex" , alignItems:"center", justifyContent:"center"}}>
      
      <RingLoader
      size={200}
      color={'var(--main-color)'}
      loading={true}
      />


    </section>
     
  );
}


export default AvhLoader

