import * as actionTypes from '../actions/actions';
import axios from 'axios';

export const getTestInfo = (name, value) =>{
return {
    type: actionTypes.TestAction.GET_INFO_TEST,
    name: name,
    value: value
}

}

export const testSubmit = (email,password) =>{
    return dispatch =>{
         var link = 'http://localhost:6337/innablink/api/getTraining/'+email+'/'+password
        axios.get(link)
            .then(r=>{
                console.log(r.data);
                dispatch(testSubmitData(r.data));
            }
            ).catch(err=>{
                console.log('Somthing wrong. Please try again ...');
            })
    }
}

export const testSubmitData = (data) =>{
    return {
        type: actionTypes.TestAction.SUBMIT_TEST,
        dataR: data
    }
}

