import { Grid } from "@material-ui/core";
import "./Startup.css";
import React from "react";
import BusinessPages from "../../../../../src/components/BusinessPages/BusinessPages";
import TextImgImgBigger from "../../../../components/TextImgImgBigger/TextImgImgBigger";
import { TextImageData, StartupData } from "./StartupData";

function Startup() {
  return (
    <Grid>
      <BusinessPages
        whatwedo={StartupData.whatwedo}
        WhiteTitle={StartupData.WhiteTitle}
        description={StartupData.description}
        BusinessImage={StartupData.BusinessImage}
      >
        <Grid item xs={12} className="grr">
          <TextImgImgBigger
            Animated
            title={TextImageData.title}
            TextContent={TextImageData.desc}
            img={TextImageData.Image}
          />
        </Grid>
      </BusinessPages>
    </Grid>
  );
}

export default Startup;
