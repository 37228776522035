import img2 from './RetailImages/TextImgRetail.png'
import img from './RetailImages/RetailImage.png'

export const RetailData = {

    whatwedo: "WHAT WE DO",
    WhiteTitle: "RETAIL",
    description: "AVH supports mid-sized companies, small businesses and communities looking to introduce or scale existing online retail. Our eCommerce platforms are state-of-the-art and fully equipped to satisfy the ever-evolving expectations of today’s digital consumers.",
    BusinessImage: img,
};

export const TextImgsRetail = {

    title: "Advanced Digital Platform for eCommerce",
    TextContents: "NUMERCE is a sophisticated digital marketplace for eCommerce services. It easy to implement and scale, using customer insights to create fully personalised offerings and rich online experiences to drive online sales.",
    Image: img2,
};
