import img from "./images/img.png";
import img1 from "./images/img2.png";

export const BankingAndFinanceData = {
  whatwedo: "WHAT WE DO",
  WhiteTitle: "BANKING",
  AquaTitle: "& FINANCE",
  BusinessImage: img,
};
export const LargeImageTextData = {
  title: "Advanced Digital Platform for Aviation",
  desc1:
    "NUMZ is a state-of-the-art digital banking platform, offering fast transactions with ultra-secure storage of passwords and payment information. NUMZ is easy to deploy and reshapes service delivery to exceed customer expectations. NUMZ gets clients ready for a world without physical wallets, and also provides rich data on customer buying trends.",
  desc2:
    "NUTRADE is an online trading marketplace to satisfy the demand for quick, easy and effective financial trading from anywhere. NUTRADE is a fully integrated platform that enables a fast-transaction, seamless trading environment, with sophisticated research, monitoring and trading tools to inform the best investment strategy for each trader.",
  Image: img1,
};
